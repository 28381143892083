import axios from 'axios';
//  "proxy": "http://localhost:4000",
import {getDataToken,dataDestroy,} from './SessionServ';
import {constantes}  from '../components/constantes';
import config from "../support/config";

export const API_URL = `${config.apiBaseUrl}`; 

const instance = axios.create({
    baseURL: API_URL,
});

/*
const instance = axios.create({
  baseURL: `/api/`
});
*/


instance.interceptors.request.use(function (config) {
  //console.log(`${config.url} ${config.url.split('?')[0]}`);
  if ( constantes.noRequireAutorizacionToken.indexOf(config.url.split('?')[0]) === -1 && 
       !(config.url.includes(constantes.baseURLEnviarLinkResetPAssword))) {
    const dataToken = getDataToken();
    if(dataToken!==null && dataToken!==undefined && dataToken!==''  && 
       dataToken.tokenType!==undefined && dataToken.tokenType!=='' &&
       dataToken.token!==undefined && dataToken.token!==''){
        config.headers['Authorization'] = `${dataToken.tokenType} ${dataToken.token}`;
    }  
  }
  return config;
}, function (error) {
  //console.error("ERROR API REQ:",error);
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {

    //console.error("ERROR API RESP:",error);

    if(error.response!==undefined && error.response.status === 403){
      
      try{        
        dataDestroy();
        //console.log(error);
      }catch(e){
        console.error("API interceptors.response error en proceso de destruccion de datos...",e);
      }
        //refreshToken()
    }
    if(error.response!==undefined && error.response.status === 401){
      try{        
        dataDestroy();
        //console.log(error);
      }catch(e){
        console.error("API interceptors.response error en proceso de destruccion de datos...",e);
      }
     // window.location.href = "/";
     // console.log(error);
       //refreshToken()
    }

    if(error.response!==undefined && error.response.status === 404){
      try{        
        dataDestroy();
        //console.log(error);
      }catch(e){
        console.error("API interceptors.response error en proceso de destruccion de datos...",e);
      }
      //window.location.href = "/";
     // console.log(error);
       //refreshToken()
    }

    if(error.response!==undefined && error.response.status === 500){
      dataDestroy();
      //console.log(error);
      window.location.href = '/no-available';
      //refreshToken()
    }
    

  return Promise.reject(error);
});

export default instance;