// menu  busqueda ejemplo canal WEB para la seccion MENU_BUSQUEDA

export const menuFooter = [
    {
    menu:"INFORMACION",
    lista:[
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/informacion-entrega","alias":"DELIBERY_INFO","idAccion":1,"nombre":"Información entrega","estatus":"ACTIVO","descripcion":"opción lista los parametros del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":1,"esSubMenu":false,"idaccionPadre":0},
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos/descuentos","alias":"DISCOUNT","idAccion":2,"nombre":"Descuentos","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":2,"esSubMenu":false,"idaccionPadre":0},    
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/mapa-sitio","alias":"SITEMAP","idAccion":3,"nombre":"Mapa Sitio","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":3,"esSubMenu":false,"idaccionPadre":0},    
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/politicas-privacidad","alias":"POLICY_PRIVACY","idAccion":4,"nombre":"Política de privacidad","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":3,"esSubMenu":false,"idaccionPadre":0},    
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/preguntas-frecuentes","alias":"FAQS","idAccion":5,"nombre":"Preguntas Frecuentes","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":4,"esSubMenu":false,"idaccionPadre":0},          
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/terminos-condiciones","alias":"TERMS_CONDITIONS","idAccion":6,"nombre":"Terminos y condiciones","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":5,"esSubMenu":false,"idaccionPadre":0},          
    ]
    },
    {
    menu:"SITIO",
    lista:[ 
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/mapa-sitio","alias":"SITEMAP","idAccion":7,"nombre":"Mapa Sitio","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":1,"esSubMenu":false,"idaccionPadre":0},    
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/mapa-sitio","alias":"NEWS","idAccion":8,"nombre":"Noticias","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":2,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos/tendencias","alias":"TRENDS","idAccion":9,"nombre":"Tendencias","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":3,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/ayuda","alias":"ABOUT","idAccion":10,"nombre":"Ayuda","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":4,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/principal/18","alias":"CONTACT_US","idAccion":11,"nombre":"Contactanos","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":4,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos","alias":"PRODUCTS","idAccion":12,"nombre":"Productos","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":4,"esSubMenu":false,"idaccionPadre":0},            
    ]
    }, 
    {
    menu:"SERVICIO",
    lista:[  
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/cuenta","alias":"ACCOUNT","idAccion":13,"nombre":"Cuenta","estatus":"ACTIVO","descripcion":"opción lista los parametros del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":2,"esSubMenu":false,"idaccionPadre":0},
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos/lista-deseos","alias":"WISHLIST","idAccion":14,"nombre":"Lista de deseos","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/carrito-compra","alias":"SHOPPING_CART","idAccion":15,"nombre":"Carrito compra","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/politica-reintegro","alias":"RETURN_POLICY","idAccion":16,"nombre":"Politica de reintegro","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos/especiales","alias":"SPECIAL","idAccion":17,"nombre":"Especiales","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},            
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/productos/combos","alias":"COMBOS","idAccion":18,"nombre":"Combos","estatus":"ACTIVO","descripcion":"opción gestión de la tabla Menú del sistema","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},            
    ]
    }, 
];