import React from "react";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import './saleSpecialContainer.css';

import { getContenidoByTipo,contenidoURL } from '../services/contenidoServ';

import shortBanner4 from '../asset/images/shortBanner4.png';
import shortBanner5 from '../asset/images/shortBanner5.png';
import shortBanner6 from '../asset/images/shortBanner6.png';

class SaleSpecialContainer extends React.Component{

    constructor(props) {
        super(props);
        this.state ={listContentSpecialSale:[],};
    }

    componentDidMount() {
      this.listarContendido();         
   }
  
   listarContendido(){
    getContenidoByTipo('GRAN_OFERTA').then(result => {
         let  datos = result.data.data;       
         this.setState({ listContentSpecialSale:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
    }

    createItemLefth(listItem){
      const  {t}=this.props;
      return listItem.sort(function(a, b){ 
        if (a.orden > b.orden)  return 1;
        if (a.orden < b.orden)  return -1;
        return 0;}).map((item)=>{
          return <Col key={item.idContenido}> 
          <div className="jtv-banner1 p-2">
            <a href="#"><img src={contenidoURL(item.idContenido)} alt="" /></a>
            <div className="hover_content">
              <div className="hover_data">
                <div className="title">{t(`${item.textoCorto}`)}</div>
                <div className="desc-text">{t(`${item.textoLargo}`)}</div>
                <div className="shop-now"><a href="#">{t('front.button.more.label')}</a></div>
              </div>
            </div>
          </div>
        </Col>
      });
  }

  createItemRigth(listItem){
    const  {t}=this.props;

    return listItem.sort(function(a, b){ 
      if (a.orden > b.orden)  return 1;
      if (a.orden < b.orden)  return -1;
      return 0;}).map((item)=>{
        return <div className="jtv-banner3" key={item.idContenido}>
        <div className="jtv-banner3-inner">
          <a href="#"><img src={contenidoURL(item.idContenido)} alt="" /></a>
          <div className="hover_content">
            <div className="hover_data">
              <div className="title">{t(`${item.textoCorto}`)}</div>
              <div className="desc-text">{t(`${item.textoLargo}`)}</div>
            </div>
          </div>
        </div>
      </div>
    });
}

    render(){

      const{listContentSpecialSale} = this.state;

      let listItemLefth = this.createItemLefth(listContentSpecialSale.filter(o => (o.orden === 1 ||  o.orden === 2)));

      let listItemRigth = this.createItemRigth(listContentSpecialSale.filter(o => o.orden === 3 ));

        return(
            <div className="top-banner">
            <Container>
              <Row xs="auto">
                <Col xs={6}>
                  <Row>
                    <Col > 
                        <div className="jtv-banner1 p-2">
                          <a href="#"><img src={shortBanner4} alt="" /></a>
                          <div className="hover_content">
                            <div className="hover_data">
                              <div className="title">765765765</div>
                              <div className="desc-text">7657657</div>
                              <div className="shop-now"><a href="#">ggg</a></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col> 
                        <div className="jtv-banner1 p-2">
                          <a href="#"><img src={shortBanner5} alt="" /></a>
                          <div className="hover_content">
                            <div className="hover_data">
                              <div className="title">ytrtrytr</div>
                              <div className="desc-text">ytrytr</div>
                              <div className="shop-now"><a href="#">jhgj</a></div>
                            </div>
                          </div>
                        </div>
                      </Col>
                  </Row>                                  
                </Col>
                <Col xs={6}>
                  {listItemRigth}
                </Col>
              </Row>
            </Container>
          </div>
        );
    }
}

export default withTranslation()(SaleSpecialContainer);