import {useEffect,useState} from "react";
import { getParametrosSistemaBySeccion } from '../services/parametroSistemaServ';
import { constantes } from './constantes';
import "./WhatsAppIcon.css";

function WhatsAppIcon(){
    const  [listParametrosSistema, setListParametrosSistema] =useState();
    const [telefono,setTelefono] =useState('');

    useEffect(()=>{
        if(listParametrosSistema===undefined){
            listarParametros();
        }
    },[]);

    useEffect(()=>{
        if(listParametrosSistema!==undefined){
            let dat =listParametrosSistema.length>0?listParametrosSistema.find(o => o.nombreParametro === constantes.footerTelefono).valor:null;
            if(dat!==null){
                dat = dat.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('+','');
            }else{
                dat =null;
            }
            setTelefono(dat);
        }
    },[listParametrosSistema]);

    const listarParametros=()=>{
        getParametrosSistemaBySeccion(constantes.variableSeccionFooterInformacion).then(result => {
             let  datos = result.data.data;
             setListParametrosSistema(datos);
        });
    }

    return(
        <>
        {
        telefono!==null && telefono!==undefined?
        <a
            href={`https://wa.me/${telefono}`}
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
        >
            <i className="fab fa-whatsapp whatsapp-icon"></i>
        </a>:<></>
      }
        </>
    );
}

export default WhatsAppIcon;