import React, { Component } from 'react';
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';

import { listaOperadores } from '../../services/usuarioServ';

import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';

import {getDataUser,setDataOperator,dataDestroy , generarSessionTemporal} from '../../services/SessionServ';
import { updateSesionCorreo } from '../../services/carritoCompraServ';


class SeleccionOperador extends Component {

    constructor(props) {
        super(props);
        let dato;
        try{
            dato = getDataUser();
        }catch(e){
            dataDestroy();
            this.props.history.push("/login");
        }
       

        this.state = {
            validated: false,
            textoMensaje:"",
            usuario:dato
        };
    }

    componentDidMount() {
        const { usuario } = this.state;
        const { loginDato , carroCompra} = this.props;       
        listaOperadores(usuario.idUsuario).then(result => {                       
            if(result.data === null){
                this.setState({ validated:false  , textoMensaje:`No se logro acceder a la plataforma`  });
            }
            setDataOperator(result.data);            

            if(carroCompra.lista!==undefined && carroCompra.lista!==null && carroCompra.lista.length!==0){
                updateSesionCorreo(
                    {sessionTemporal:generarSessionTemporal(),correoTemporal:usuario.correo}
                );
            }

            this.props.history.push( loginDato.salto);
        }).catch((error)=>{
            this.setState({ validated:false  , textoMensaje:`No se logro acceder a la plataforma`  });
        });
    }

    render () {

        return(
            <Container fluid="md" className="pt-5">
                    <img src={logo} alt="logo" className="p-1"/>
                    <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                            <Card.Img variant="top" src={imageLogin} />
                            <Card.Body>
                                    <Col className="col text-center">
                                            <h2>Selección de Operador</h2>
                                            <Spinner animation="border" variant="primary" className="col-sm-12 table-responsive p-3"/>
                                            <span className="text-dark fs-4 mt-3  p-3">Cargando plataforma...</span> 
                                    </Col> 
                            </Card.Body>
                            </Card>  
                    </Row>                                         
            </Container>
        );
    }

}

export default connect(state => ({
    loginDato: state.login,
    carroCompra:state.carrito,
  }))(SeleccionOperador)