import React, { Component } from 'react'
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';

import { login } from '../../services/usuarioServ';

import {setDataUser,setDataToken} from '../../services/SessionServ';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MensajeAlert from '../../components/MensajeAlert';
import { withTranslation } from 'react-i18next';

class Login extends Component {

   constructor(props) {
        super(props);
        this.state = {
            validated: false,
            textoMensaje:"",
            usuario:{correo:"",password:""},
            variante:"danger"
        };
   }

   myChangeHandler = (event) => {

    const { usuario } = this.state;
   
    let nam = event.target.name;
    let val = event.target.value;

    usuario[nam]=val;

    this.setState({usuario: usuario});
  }

  handleSubmit = (event) => {
    this.setState({ validated: false,textoMensaje:"" });
    const { usuario } = this.state;
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
    }else{
        login(usuario).then(result => {           
            if(result.data === null){
                throw new Error("No se logro acceder a la plataforma");
            }
            setDataUser(result.data);
            setDataToken(result.data);
            this.props.history.push("/seleccionOperador");
        }).catch((error)=>{
            this.setState({ validated:false  , textoMensaje:`No se logro acceder a la plataforma`,variante:"danger"  });
        });
        event.preventDefault();
    }
};

  render () {
    const { usuario , textoMensaje,validated,variante} = this.state;
    const  {t}=this.props;
    return (
            <Container fluid="md" className="p-5">
                    <img src={logo} alt="logo" className="p-1"/>
                    <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                            <Card.Img variant="top" src={imageLogin} />
                            <Card.Body>
                                    <Col className="col text-center">
                                            <h1>{t('front.login.login.tittle.text')}</h1>
                                            { textoMensaje!==""?<MensajeAlert mensaje={textoMensaje} variante={variante} />:<></> }  
                                            <Form className="p-3 text-center" noValidate validated={validated} onSubmit={this.handleSubmit} >                  
                                                <FloatingLabel  controlId="floatingInputCorreo"  label={t('front.login.login.email.label')}  className="mb-3" >
                                                    <Form.Control required type="email" name="correo" placeholder="Correo" value={usuario.correo} onChange={this.myChangeHandler} />
                                                </FloatingLabel>
                                                <FloatingLabel  controlId="floatingInputPass"  label={t('front.login.login.password.label')}  className="mb-3" >
                                                    <Form.Control required type="password" name="password" placeholder="Password" value={usuario.password} onChange={this.myChangeHandler} />
                                                </FloatingLabel>  
                                                <div className="d-grid gap-2 d-md-block m-3 text-center">     
                                                    <button type="button" className="btn btn-outline-danger ms-1"  onClick={()=>{ this.props.history.push("/"); }}><i className="fas fa-chevron-left"></i>{t('front.login.button.back.text')}</button>                                                        
                                                    <button type="submit" value="Entrar" className="btn btn-success mx-1">{t('front.login.button.enter.text')}</button> 
                                                </div>
                                                <div className="d-grid gap-2 d-md-block m-3 text-center">
                                                        <button type="button" className="btn btn-outline-success mx-1 btn-sm" onClick={()=>{ this.props.history.push("/olvidoClave"); }} >{t('front.login.button.forgotPassword.text')}</button>                    
                                                        <button type="button" className="btn btn-outline-success ms-1 btn-sm"  onClick={()=>{ this.props.history.push("/registroUsuario"); }}>{t('front.login.button.registerUser.text')}</button>
                                                </div>                              
                                            </Form> 
                                    </Col> 
                            </Card.Body>
                            </Card>  
                    </Row>                                         
            </Container>
    );
  }
}

export default withTranslation()(Login);