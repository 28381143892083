import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './bannerStatic.css';
import { withTranslation } from 'react-i18next';
import { getContenidoByTipo,contenidoURL } from '../services/contenidoServ';

import shortBanner1 from '../asset/images/shortBanner1.png';
import shortBanner2 from '../asset/images/shortBanner2.png';
import shortBanner3 from '../asset/images/shortBanner3.png';

class BannerStatic extends React.Component{

    constructor(props) {
        super(props);
        this.state ={listStatic:[],};
    }

    componentDidMount() {
      this.listarContendido();         
    }
  
   listarContendido(){
    getContenidoByTipo('BANNER_OFERTA').then(result => {
         let  datos = result.data.data;         
         this.setState({ listStatic:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
    }

    createItem(listItem){
      const  {t}=this.props;
      return listItem.sort(function(a, b){ 
        if (a.orden > b.orden)  return 1;
        if (a.orden < b.orden)  return -1;
        return 0;}).map((item,index)=>{
        return (<Col key={index} sm>
        <div className="banner-box banner-box2">
          <a href=""><img src={contenidoURL(item.idContenido)} alt="" /></a>
          <div className="box-hover">
            <div className="banner-title">{t(`${item.textoCorto}`)}</div>
            <span>{t(`${item.textoLargo}`)}</span>
          </div>
        </div>
      </Col>);
      });
  }

    render(){

    const{listStatic} = this.state;

    let list = this.createItem(listStatic);

    return(  
        <Container className="banner-static">
          <Row>
            <Col sm>
              <div className="banner-box banner-box2">
                <a href=""><img src={shortBanner1} alt="" /></a>
                <div className="box-hover">
                  <div className="banner-title">Retos</div>
                  <span>Reta a otros grupos</span>
                </div>
              </div>
            </Col>
            <Col sm>
              <div className="banner-box banner-box2">
                <a href=""><img src={shortBanner2} alt="" /></a>
                <div className="box-hover">
                  <div className="banner-title">Control de Reserva</div>
                  <span>Serva de forma fácil</span>
                </div>
              </div>
            </Col>
            <Col sm>
              <div className="banner-box banner-box2">
                <a href=""><img src={shortBanner3} alt="" /></a>
                <div className="box-hover">
                  <div className="banner-title">Crea Grupos</div>
                  <span>Realiza Grupos de partidos</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    
    );}}

    export default withTranslation()(BannerStatic);