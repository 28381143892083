import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';

function MensajeLoad(props) {
    return (         
        <div className="col-sm-12 text-center p-3">
                <Spinner animation="grow" variant="primary" className="col-sm-12 table-responsive p-3"/>
                <span className="text-secondary fs-5">{props.mensaje}</span>
        </div>
    );
}

MensajeLoad.defaultProps = {
    mensaje:"Cargando datos...",
}

export default MensajeLoad;