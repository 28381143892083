import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";

import { constantes } from './constantes';
import {optionsSelect,convertDateTimeToFormatAPI,convertDateAPIToOnlyDDMMYYYHHMMSS,subtractYears} from './Utils';

import { uploadImagenPerfil,getUsuarioById } from '../services/usuarioServ';

import { addPersona} from '../services/personaServ';

import avatarDefault from '../asset/images/default_user.jpg';

import {getDataUser,setDataUser,dataDestroy, getDataOperator,setDataOperator} from '../services/SessionServ';

import Mensaje  from './Mensaje';

export class PerfilUsuarioLogeado extends Component {


    constructor(props) {
        super(props);
        let dato;
        let datoOperador;

        try{
            dato = getDataUser();
        }catch(e){
            dataDestroy();
            this.props.history.push("/login");
        }

        try{
            datoOperador = getDataOperator();
        }catch(e){
            dataDestroy();
            this.props.history.push("/login");
        }

        let fecha = datoOperador.persona.fechaNacimiento===undefined? subtractYears(new Date(),constantes.edadMinimaPersona):new Date(`${convertDateAPIToOnlyDDMMYYYHHMMSS(datoOperador.persona.fechaNacimiento)}`);
        let idUsuario =dato.idUsuario;
        this.state = {
            validated: false,
            textoMensaje:"",
            usuario:dato,
            idUsuario:idUsuario,
            operador:datoOperador,
            fechaNacimiento:fecha,
            persona: datoOperador.persona,
            mensaje:false,
        };
    }

    setStartDate =(date)=>{
        let trans=convertDateTimeToFormatAPI(date);        
        const { persona } = this.state;
        persona.fechaNacimiento=trans;
        this.setState({fechaNacimiento: date,persona:persona});
    }

    onFileChangeHandler = (e) => { 
        const { usuario,idUsuario} = this.state;
        const formData = new FormData();
        formData.append('file', e.target.files[0]);  

        uploadImagenPerfil(idUsuario,formData).then(result => {
            getUsuarioById(idUsuario).then(result=>{
                usuario.imagePerfilUTF=result.data.imagePerfilUTF;
                usuario.idUsuario=result.data.idUsuario;
                usuario.id=result.data.idUsuario;
                setDataUser({...usuario});
                this.setState({usuario:{...usuario}});            
            });            
        }).catch((error)=>{
        this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
        }); 
    };

    myChangeHandler = (event) => {
        const { persona } = this.state;   
        let nam = event.target.name;
        let val = event.target.value;
        persona[nam]=val;
        this.setState({persona: persona});
    }

    regresar() {
        this.props.history.push("/usuario");
    }

    render () { 

        const { usuario, operador,validated,mensaje, textoMensaje, variante,fechaNacimiento,persona,} = this.state;

        const handleSubmit =  async (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                this.setState({ validated: true });
               
                event.stopPropagation();
            }else{
                this.setState({ mensaje:false , textoMensaje:``});
                addPersona(persona).then(result => {
                    operador.persona=persona;
                    setDataOperator(operador);
                    this.setState({operador:operador, cargando:false , mensaje:true , textoMensaje:`Actualización aplicada correctamente` , variante:"success" });
                }).catch((error)=>{
                   this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
                });
                event.preventDefault();
            }
        };

        return (
        <>  
                <Mensaje variant={variante} message={textoMensaje} show={mensaje} showMessage={(show)=>{ this.setState({mensaje:show})}} />
                <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <center>
                                    <span>
                                        <img className="rounded-circle mt-2" style={{width:"250px",height:"250px"}} src={usuario.imagePerfilUTF!=null?usuario.imagePerfilUTF:avatarDefault} alt="User" />                                        
                                    </span>
                                </center>
                                <Form.Group controlId="formFileSm" className="m-2">
                                        <Form.Label>{`Cambiar Imagen de perfil:  `}</Form.Label>
                                        <Form.Control type="file" size="sm" name="fileAvatar" accept="png,jpg" className="ml-3" onChange={this.onFileChangeHandler} />
                                        <Form.Control.Feedback type="invalid">
                                            Archivo requerido.
                                        </Form.Control.Feedback>
                                </Form.Group>
                                <Form className="p-3" noValidate validated={validated} onSubmit={handleSubmit} >
                                <Row className="mb-3">
                                <h4>Datos Personales:</h4>
                                    <Form.Group  as={Col}  controlId="formPlaintextPassword">
                                        <Form.Label>Nombres</Form.Label>
                                        <Form.Control type="text" required name="nombres" placeholder="Nombres" value={persona.nombres} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                    <Form.Group  as={Col} controlId="formPlaintextPassword">
                                        <Form.Label>Apellidos</Form.Label>
                                        <Form.Control type="text" required  name="apellidos" placeholder="Apellidos" value={persona.apellidos} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formPlaintextIdentificacion">
                                        <Form.Label>Tipo de identificación</Form.Label>                                        
                                        <Form.Control as="select" required  value={persona.tipoIdentificacion}  onChange={this.myChangeHandler} name="tipoIdentificacion" >
                                            {optionsSelect(constantes.tipoIdentificacion,true)}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col}  controlId="formPlaintextPassword">
                                        <Form.Label>Número de identificación</Form.Label>
                                        <Form.Control type="text" required name="numeroIdentificacion" placeholder="Número de Identificación" value={persona.numeroIdentificacion} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                    <Form.Group as={Col}  controlId="exampleForm.ControlInput1">
                                        <Form.Label>Correo</Form.Label>
                                        <Form.Control disabled type="email" name="correo" placeholder="name@example.com" value={persona.correo} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}   controlId="formPlaintextFechaNacimiento">
                                        <Form.Label>Fecha de Nacimiento  / edad mínima: {constantes.edadMinimaPersona} años</Form.Label> 
                                        <DatePicker utcOffset={0} required  maxDate={subtractYears(new Date(),constantes.edadMinimaPersona)} dateFormat={constantes.formatoFecha} className="form-control" onChange={(date) => this.setStartDate(date)} name="fechaNacimiento" selected={fechaNacimiento}   />  
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formPlaintextPassword">
                                        <Form.Label>Género</Form.Label>
                                        <Form.Control required as="select" value={persona.genero} onChange={this.myChangeHandler} name="genero" >
                                            {optionsSelect(constantes.genero,true)}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formPlaintextPassword">
                                        <Form.Label>Número de teléfono 1</Form.Label>
                                        <Form.Control  type="text" required  pattern={constantes.patternPhone} name="numeroTelefono1" placeholder={constantes.placeholderPhone} value={persona.numeroTelefono1} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formPlaintextPassword">
                                        <Form.Label>Número de teléfono 2</Form.Label>
                                        <Form.Control  type="text"  pattern={constantes.patternPhone} name="numeroTelefono2" placeholder={constantes.placeholderPhone} value={persona.numeroTelefono2} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formPlaintextPassword">
                                        <Form.Label>Número de teléfono 3</Form.Label>
                                        <Form.Control  type="tel"  pattern={constantes.patternPhone} name="numeroTelefono3" placeholder={constantes.placeholderPhone} value={persona.numeroTelefono3} onChange={this.myChangeHandler} />
                                    </Form.Group>
                                </Row>                                
                                    <div className="mx-auto" style={{ width: "100px" }}>
                                        <Button type="submit" >Actualizar</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>          
        </> 
     ); 
    }
}

export default withRouter(PerfilUsuarioLogeado);