import React from "react";
import FooterCoppyright from "./footerCoppyright";
import FooterInformation from "./footerInformation";

export class Footer extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
          <footer>              
              { this.props.listContenido.some(item =>item === 'FOOTER_INFORMACION')?<FooterInformation { ...this.props }/>:<></>}
              { this.props.listContenido.some(item =>item === 'PIE_PAGINA_COPYRIGTH')?<FooterCoppyright { ...this.props }/>:<></>}
          </footer>
            );
    }
}