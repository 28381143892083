import { ActionTypes } from "../constants/action-types";


const initialState = {
    lista:{},
    producto:{},
};

export const carritoCompraReducer=(state = initialState,action)=>{
     switch (action.type) {
        case ActionTypes.ADD_PRODUCTO_CARRITO:             
            return {...state, producto:action.payload};  
        case ActionTypes.FETCH_CARRITO:
            return { ...state, lista: action.payload};
        case ActionTypes.REMOVE_PRODUCTO_CARRITO:
            return {...state, producto:action.payload};
        default:
            return state;
     }
}