import React from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { multimediaProductoURL } from '../services/multimediaServ';
import imageDefaultProduct  from '../asset/images/large-default-product.jpg';
import { getProductosByTipoBusqueda } from "../services/productoServ";
import './topSellerProductContainer.css';
import './hotProducts.css';

import { withTranslation } from 'react-i18next';

class TopSellerProductContainer extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          listaProductosTop: undefined,
          listaProductosMasVendidos:undefined,
          cargandoTop:true,
          cargandoMasVendidos:true,
          listaProductosDia: undefined,
          cargandoProductosDia:true,
          mensaje:true,
          textoMensaje:'',
          variante:'',
      };     
    }

    componentDidMount()
      {
        this.cargarProductosTop(); 
        this.cargarProductosMasVendidos(); 
        this.cargarProductosDia(); 
    }

    cargarProductosDia(){
      let pagination=0;
      let row=100;
      let filter=[{parametro:"TIPO_BUSQUEDA",valor:"PRODUCTO_DIA"}];
      getProductosByTipoBusqueda(pagination,row,filter).then(result => {
            let  datos = result.data.data;
            this.setState({ listaProductosDia: [...datos], cargandoProductosDia:false  });
      }).catch((error)=>{
        this.setState({ cargandoProductosDia:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
        });
    }

    cargarProductosTop(){
      let pagination=0;
      let row=100;
      let filter=[{parametro:"TIPO_BUSQUEDA",valor:"TOP_PRINCIPAL"}];
      getProductosByTipoBusqueda(pagination,row,filter).then(result => {
            let  datos = result.data.data;
            this.setState({ listaProductosTop: [...datos], cargandoTop:false  });
      }).catch((error)=>{
        this.setState({ cargandoTop:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
        });
    }

    cargarProductosMasVendidos()
    {
      let pagination=0;
      let row=100;
      let filter=[{parametro:"TIPO_BUSQUEDA",valor:"MAS_VENDIDOS"}];
      getProductosByTipoBusqueda(pagination,row,filter).then(result => {
            let  datos = result.data.data;
            this.setState({ listaProductosMasVendidos: [...datos], cargandoMasVendidos:false  });
      }).catch((error)=>{
        this.setState({ cargandoMasVendidos:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
        });
    }

    onImageError(e) {
      e.target.src = imageDefaultProduct;
    }

    goDetalle(producto){
      this.props.history.push(`/producto/detalle/${producto.idProducto}`);
    }
    
    render(){
    const  {t}=this.props;
    const { listaProductosTop,listaProductosMasVendidos,cargandoTop,cargandoMasVendidos,listaProductosDia,cargandoProductosDia} = this.state;
    return(  
        <Container>
          <Row> 
            <Col  md={9}>
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title={t('front.topSeller.tab1.label')}>
                  <Container className="home-tab">
                    <Row>
                      {
                        listaProductosTop!==undefined?
                        listaProductosTop.map((dat,idx)=>{
                          return(
                            <Col key={idx}>
                              <Card >
                                <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{width:"5wh",height:"30vh"}} onError={this.onImageError} />
                                <Card.Body>
                                  <Card.Title>{dat.nombre}</Card.Title>
                                  <Card.Text>
                                    {dat.descripcion}
                                  </Card.Text>
                                  <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver detalle...</Button>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                        :
                        <>cargando...</>
                      }
                    </Row>
                  </Container>
                </Tab>
                <Tab eventKey="profile" title={t('front.topSeller.tab2.label')}>      
                  <Container className="home-tab">
                    <Row>
                    {
                        listaProductosMasVendidos!==undefined?
                        listaProductosMasVendidos.map((dat,idx)=>{
                          return(
                            <Col key={idx}>
                              <Card >
                                <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{width:"5wh",height:"30vh"}} onError={this.onImageError} />
                                <Card.Body>
                                  <Card.Title>{dat.nombre}</Card.Title>
                                  <Card.Text>
                                  {dat.descripcion}
                                  </Card.Text>
                                  <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver detalle...</Button>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                        :
                        <>cargando...</>
                      }
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </Col>          
            <Col md={3} style={{border:"0px", borderColor:"0px !important",minHeight:"100%"}} >
              <div className="hot-deal"> <span className="title-text">{t('front.topSeller.hotDeal.label')}</span>
                      {
                        listaProductosDia!==undefined?
                        listaProductosDia.map((dat,idx)=>{
                          return(
                              <Card key={idx} style={{minHeight:"40hv"}} >
                                <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{width:"5wh",height:"30vh"}} onError={this.onImageError} />
                                <Card.Body>
                                  <Card.Title>{dat.nombre}</Card.Title>
                                  <Card.Text>
                                  {dat.descripcion}
                                  </Card.Text>
                                  <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver detalle...</Button>
                                </Card.Body>
                              </Card>
                          );
                        })
                        :
                        <>Cargando...</>
                      }
              </div>
            </Col>
          </Row>
      </Container> 
    );}
}

export default withTranslation()(withRouter(TopSellerProductContainer));