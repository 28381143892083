import React, { Component } from 'react'
import {dataDestroy} from '../../services/SessionServ';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { withTranslation } from 'react-i18next';

class NotFoundContent extends Component {

  cerrarSession = () =>{             
    dataDestroy(); 
    this.props.history.push("/");
  }

  render () {
    const  {t}=this.props;
    return (     
        <Container fluid="md" className="p-5">       
        <Row className="justify-content-md-center">                
        <Card style={{"width":"25rem"}}>                                
                <Card.Body>
                        <Col className="col text-center">
                            <i className="far fa-times-circle fa-10x" style={{"color":"Tomato"}} ></i>
                            <h2>{t('front.notFoundContent.urlBreak.shotText')}</h2>
                            <p>{t('front.notFoundContent.urlBreak.longText')}</p>                            
                        </Col> 
                </Card.Body>
                </Card>  
        </Row>                                         
        </Container>
    )
  }
}

export default withTranslation()(NotFoundContent);