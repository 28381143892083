import { ActionTypes } from "../constants/action-types";


const initialState = {
    salto:"/usuario/",
};

export const loginReducer=(state = initialState,action)=>{
     switch (action.type) {
        case ActionTypes.SALTO_LOGIN:             
            return {...state, salto:action.salto};         
        default:
            return state;
     }
}