import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getMonedas(pagination,row,filter){

    try{
        const response = await API.get(`/moneda?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getMonedasDisponibles(){

    try{
        const response = await API.get(`/moneda/activa`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteMoneda(moneda){
    try{
        const response = await API.delete(`/moneda/${moneda.iso_moneda}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function updateMoneda(moneda){
    try{
        const response = await API.get(`/moneda/estatus/${moneda.iso_moneda}/${moneda.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addMoneda(moneda)
{
    try{
        const response = await API.post(`/moneda`, moneda , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}