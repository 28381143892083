import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { withTranslation } from 'react-i18next';

import './latestNew.css';

import { getContenidoByTipo,contenidoURL } from '../services/contenidoServ';

class LatestNew extends React.Component{

    constructor(props) {
        super(props);
        this.state ={listContentNew:[],};
    }

    componentDidMount() {
      this.listarContendido();         
   }
  
   listarContendido(){
    getContenidoByTipo('ULTIMAS_NOTICIAS').then(result => {
         let  datos = result.data.data;         
         this.setState({ listContentNew:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
    }

    createItem(listItem){
      return listItem.sort(function(a, b){ 
        if (a.orden > b.orden)  return 1;
        if (a.orden < b.orden)  return -1;
        return 0;}).map((item)=>{
          return <Col xs={6} md={4} key={item.idContenido}>
          <Card >
                    <div className="jtv-blog">
                      <div className="blog-img"> <a href="#"> <img className="primary-img" src={contenidoURL(item.idContenido)} alt="" /></a> <span className="moretag"></span> </div>
                      <div className="blog-content-jtv">
                        <h2><a href="#">{item.textoCorto}</a></h2>
                        <p>{item.textoLargo}</p>
                        <span className="blog-likes"><i className="fa fa-heart"></i> 149 likes</span> <span className="blog-comments"><i className="fa fa-comment"></i> 80 comments</span>
                        <div className="blog-action"> <span>Jan, 20, 2017</span> <a className="read-more" href="#">read more</a> </div>
                      </div>
                    </div>
              </Card>
          </Col>
      });
  }

    render(){

      const{index,listContentNew} = this.state;
      const  {t}=this.props;
      let list = this.createItem(listContentNew);

      return(
        <Container>
          <div id="latest-news" className="news">
            <div className="page-header">
              <h2>{t('front.latestNew.tittle.label')}</h2>
            </div>
            <div className="slider-items-products">
              <Row>
                {list}
              </Row>
            </div>
          </div>
        </Container>
        );
    }

}

export default withTranslation()(LatestNew);