function HeadTableData(props) {
    return (         
        <thead>
               {props.children}
        </thead>
    );
}

HeadTableData.defaultProps = {
    children:<tr><td>NO DATA HEAD</td></tr>,
}

export default HeadTableData;