import React from "react";

import visaLogo from  '../asset/images/payment/visa.png';
import paypalLogo from  '../asset/images/payment/paypal.png';
import discoverLogo from  '../asset/images/payment/discover.png';
import mcLogo from  '../asset/images/payment/master-card.png';

import './footerCoppyright.css';

import { getParametrosSistemaBySeccion } from '../services/parametroSistemaServ';
import { constantes } from './constantes';
import { withTranslation } from 'react-i18next';

class FooterCoppyright extends React.Component{

  constructor(props) {
        super(props);
        this.state = {
          listContentCoopyRigth:[],
      };
  }

  componentDidMount() {
      this.listarParametros();         
  }
  
  listarParametros(pagination,row,filter){
    getParametrosSistemaBySeccion(constantes.variableSeccionCopyRigthInformacion).then(result => {
         let  datos = result.data.data;
         this.setState({ listContentCoopyRigth:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
  }

    render(){

      const { listContentCoopyRigth } = this.state;
      const  {t}=this.props;

      let nombreSistema = listContentCoopyRigth.length>0?listContentCoopyRigth.find(o => o.nombreParametro === constantes.nombreSistema):null;

      let metodosPago =  listContentCoopyRigth.length>0?listContentCoopyRigth.find(o => o.nombreParametro === constantes.metodosPagoPiePAgina):null;

      let splitDatos = metodosPago!=null?metodosPago.valor.split(","):[];
      
        return(
            <div className="footer-coppyright">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 coppyright">Copyright © 2023 <a href="#">{nombreSistema!=null?nombreSistema.valor:''}</a>. Derechos reservados</div>
                <div className="col-sm-6 col-xs-12">
                  <div className="payment">
                    <ul>
                    
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
    );}
}

export default withTranslation()(FooterCoppyright);