import React from "react";

import { subscribirANoticia } from "../services/usuarioServ";
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import MensajeAlert from './MensajeAlert';

class Subscribe extends React.Component{

    constructor(props) {
        super(props);
        this.state = {validated: false,correo:"", mensaje:false};
    }

    render(){

      const { correo,validated,mensaje , textoMensaje, variante } = this.state;

      const  {t}=this.props;

      const handleSubmit =  async (event) => {

        const form = event.currentTarget;
        this.setState({ mensaje:false });
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }else{
          subscribirANoticia(correo).then(result => {
               this.setState({ cargando:false , mensaje:true , textoMensaje:`${t('front.subscribe.message.success.text')} ${correo}` , variante:"success" }); 
            }).catch((error)=>{
              let texto = error.message.includes('CORREO_UNIQUE')?`${t('front.subscribe.message.error.text')}`:`${t('front.generic.message.error.text')}`; 
              this.setState({ cargando:false , mensaje:true , textoMensaje:texto , variante:"danger" });
            });
            event.preventDefault();
        }
    };

        return(
            <Form id="newsletter-validate-detail" noValidate validated={validated} onSubmit={handleSubmit} >
            <h3 className="hidden-sm">Requiere Más información</h3>
            {mensaje?<MensajeAlert variante={variante} mensaje={textoMensaje}/>:<></>}
            <div className="newsletter-inner">
             <InputGroup className="mb-3">
                <Form.Control className="newsletter-email" type="email" required name='email' placeholder="email"  value={correo} onChange={(e)=>{ this.setState({correo: e.target.value});}} />
                <Button className="subscribe" type="submit" title="Subscribirse">Solicitar <i className="fas fa-concierge-bell"></i></Button>
              </InputGroup>
            </div>
          </Form>
    );}
}

export default withTranslation()(Subscribe);