import React, { Component } from 'react';
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';


import { envioLinkReseteo } from '../../services/usuarioServ';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MensajeAlert from '../../components/MensajeAlert';

import { withTranslation } from 'react-i18next';

class OlvidoClave extends Component {

  constructor(props) {
        super(props);
        this.state = {
            validated: false,
            textoMensaje:"",
            usuario:{correo:""},
            tipoMensaje:""
        };
   }

   myChangeHandler = (event) => {

    const { usuario } = this.state;
   
    let nam = event.target.name;
    let val = event.target.value;
    console.log(`${nam} : ${val}`);

    usuario[nam]=val;

    this.setState({usuario: usuario});
  }

  handleSubmit = (event) => {
    
    this.setState({ validated:false  , textoMensaje:"", tipoMensaje:"danger"  });
    const  {t}=this.props;
    const { usuario } = this.state;
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
    }else{
        envioLinkReseteo(usuario).then(result => {           
            if(result.data === null){
                throw new Error(t('front.generic.message.error.text'));
            }
            this.setState({ validated:true ,  usuario:{correo:""} , textoMensaje:t('front.lostPassword.message.success.text'), tipoMensaje:"primary"  });          
        }).catch((error)=>{
            this.setState({ validated:false  , textoMensaje:t('front.generic.message.error.text'), tipoMensaje:"danger"  });
        });
        event.preventDefault();
    }
};

    render () {

        const { usuario , textoMensaje,tipoMensaje,validated} = this.state;
        const  {t}=this.props;
        return(
        <Container fluid="md" className="p-5">
                    <img src={logo} alt="logo" className="p-1"/>
                    <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                            <Card.Img variant="top" src={imageLogin} />
                            <Card.Body>
                                    <Col className="col text-center">
                                            <h1>{t('front.lostPassword.lostPassword.tittle.text')}</h1>
                                            { textoMensaje!==""?<MensajeAlert mensaje={textoMensaje} variante={tipoMensaje} />:<></> }  
                                            <Form className="p-3 text-center" noValidate validated={validated} onSubmit={this.handleSubmit} >                  
                                                <FloatingLabel  controlId="floatingInputCorreo"  label={t('front.lostPassword.email.label.text')}  className="mb-3" >
                                                    <Form.Control required type="email" name="correo" placeholder="Correo" value={usuario.correo} onChange={this.myChangeHandler} />
                                                </FloatingLabel>                                                                               
                                                <div className="d-grid gap-2 mx-auto">
                                                    <button type="submit" value="Entrar" className="btn btn-success mx-1">{t('front.lostPassword.sendEmailLinkReset.text')}</button> 
                                                    <button type="button" className="btn btn-outline-danger mx-1"  onClick={()=>{ this.props.history.push("/"); }}><i className="fas fa-chevron-left"></i>{t('front.login.button.back.text')}</button>                                                                                                     
                                                </div>
                                            </Form> 
                                    </Col> 
                            </Card.Body>
                            </Card>  
                    </Row>                                         
            </Container>);
    }

}

export default withTranslation()(OlvidoClave);