import React from "react";
import { HeaderBottom } from "./headerBottom";
import HeaderTop  from "./headerTop";
import { MobileMenu } from "./mobileMenu";
import  NavCategoria  from "./Navcategoria";

import Container from 'react-bootstrap/Container';


export class Header extends React.Component{

    

    constructor(props) {
        super(props);
    }

    render(){
        return(
          <>
            <header className="p-0 m-0">
                <Container fluid className="p-0 m-0">                
                    <HeaderTop  { ...this.props }  />
                    <HeaderBottom { ...this.props } useSuspense={false} />              
                </Container>
            </header>
            {
                this.props.listContenido.some(item =>item === 'MENU_BUSQUEDA')?
                <NavCategoria { ...this.props }  />
                :<></>
            }           
          </>
    );}
}