import React from "react";


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './serviceArea.css';

import { getContenidoByTipo } from '../services/contenidoServ';
import { withTranslation } from 'react-i18next';

class ServiceArea extends React.Component{

    constructor(props) {
        super(props);
        this.state ={listContServiceArea:[],};
    }

    componentDidMount() {
      this.listarContendido();         
    }
  
   listarContendido(){
    getContenidoByTipo('BANNER_DELIVERY').then(result => {
         let  datos = result.data.data;         
         this.setState({ listContServiceArea:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
    }

    createItem(listItem){
      const  {t}=this.props;

      return listItem.sort(function(a, b){ 
        if (a.orden > b.orden)  return 1;
        if (a.orden < b.orden)  return -1;
        return 0;}).map((item,index)=>{

          let class1="";
          let class2="";

          switch(item.alias){
              case 'truck':
                class1 = "ship";
                class2="fa-user";           
              break;
              case 'return':
                class1 = "return";
                class2="fa-futbol";           
              break;
              case 'support':
                class1 = "support";
                class2="fa-store";           
              break;
              case 'user':
                class1 = "user";
                class2="fa-newspaper";           
              break;
              default:
              class1 = "ship";
              class2="fa-truck";
          }

          return (<Col key={index} sm>
          <div className={`block-wrapper ship`}>
            <div className="text-des">
              <div className="icon-wrapper"><i className={`fa fa-user`}></i></div>
              <div className="service-wrapper">
                <h3>{t(`${item.textoCorto}`)}</h3>
                <p>{t(`${item.textoLargo}`)}</p>
              </div>
            </div>
          </div>
        </Col>);
      });
  }


    render(){

    //const{listContServiceArea} = this.state;

    //let list = this.createItem(listContServiceArea);

    return(       
        <Container className="pt-1 pb-1">
          <Row className="jtv-service-area"> 
          <Col sm>         
            <div className={`block-wrapper ship`}>
              <div className="text-des">
                <div className="icon-wrapper"><i className={`fa fa-user`}></i></div>
                <div className="service-wrapper">
                  <h3>Registrate</h3>
                  <p>Crea tu usuario de forma fácil y gratis</p>
                </div>
              </div>
            </div>
            </Col>
            <Col sm>
            <div className={`block-wrapper return`}>
              <div className="text-des">
                <div className="icon-wrapper"><i className={`fa fa-futbol`}></i></div>
                <div className="service-wrapper">
                  <h3>Programa los partidos</h3>
                  <p>Las reserva de cancha es fácil sin complicaciones</p>
                </div>
              </div>
            </div>
            </Col>
            <Col sm>
              <div className={`block-wrapper user`}>
                <div className="text-des">
                  <div className="icon-wrapper"><i className={`fa fa-newspaper`}></i></div>
                  <div className="service-wrapper">
                    <h3>Noticia de eventos y torneos</h3>
                    <p>Recibe notificaciones de los torneos y eventos de tu interes.</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm>
            <div className={`block-wrapper ship`}>
              <div className="text-des">
                <div className="icon-wrapper"><i className={`fa fa-truck`}></i></div>
                <div className="service-wrapper">
                  <h3>Compra de articulos deportivos</h3>
                  <p>En nuestra tienda online puedes comprar articulos de futbol de forma sencilla.</p>
                </div>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
    );}
}

export default withTranslation()(ServiceArea);