import { useState, useEffect } from 'react';

function RowTableData(props) {
    return (         
        <tbody>
            {props.children}                     
        </tbody>
    );
}

RowTableData.defaultProps = {
    children:<tr>NO DATA ROW</tr>,
}

export default RowTableData;