import API from './api';

import {constantes}  from '../components/constantes';

export async function getcontenidoHtmlByAliasAndISO(alias,iso)
{
    try{
        const response = await API.get(`/contenidoHtml/data/${iso}/${alias}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}