import { ActionTypes } from "../constants/action-types";


const initialState = {
    listaFavorito:undefined,
    favorito:undefined,
};

export const favoritoReducer=(state = initialState,action)=>{
    switch (action.type) {
       case ActionTypes.ADD_PRODUCTO_FAVORITO:             
           return {...state, favorito:action.payload};  
       case ActionTypes.REMOVE_PRODUCTO_FAVORITO:
            return {...state, favorito:action.payload};
       case ActionTypes.FETCH_LIST_FAVORITO:
           return { ...state, listaFavorito: undefined, favorito:undefined};
       case ActionTypes.LIST_FAVORITO:
            return { ...state, listaFavorito:action.payload, favorito:undefined};
       default:
           return state;
    }
}