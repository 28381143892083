import React from "react";

export class ModalFade extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div id="myModal" className="modal fade">
          <div className="modal-dialog newsletter-popup">
            <div className="modal-content">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              <div className="modal-body">
                <h4 className="modal-title">NEWSLETTER SIGNUP</h4>
                <form id="newsletter-form" method="post" action="#">
                  <div className="content-subscribe">
                    <div className="form-subscribe-header">
                      <label>Subscribe to be the first to know about Sales, Events, and Exclusive Offers!</label>
                    </div>
                    <div className="input-box">
                      <input type="text" className="input-text newsletter-subscribe" title="Sign up for our newsletter" name="email" placeholder="Enter your email address" />
                    </div>
                    <div className="actions">
                      <button className="button-subscribe" title="Subscribe" type="submit">Subscribe</button>
                    </div>
                  </div>
                </form>
                <div className="subscribe-bottom">
                  <input name="notshowpopup" id="notshowpopup" type="checkbox" />
                  Don’t show this popup again </div>
              </div>
            </div>
          </div>
        </div>
            );}
}