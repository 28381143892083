import React from "react";

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { withTranslation } from 'react-i18next';

import { menuBusquedaListTest } from '../test/MenuList';

class NavCategoria extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          listMenu:menuBusquedaListTest,
      };
    }

    opcionesIzquierda(){
      const { listMenu } = this.state;
      const  {t}=this.props;
      let listMenuIzquiezda = listMenu.find(o => o.menu === 'MENU_BUSQUEDA_IZQUIERDA');
      return listMenuIzquiezda.lista.map((opcion)=>{
        if(opcion.esSubMenu===false && opcion.idaccionPadre===0){
          return <Nav.Link key={opcion.idAccion} href={opcion.path}>{t(`${opcion.nombre}`)}</Nav.Link>;
        }  
        if(opcion.esSubMenu===true  && opcion.idaccionPadre===0){
           return <NavDropdown key={opcion.idAccion} title={t(`${opcion.nombre}`)} id={`collasible-${opcion.alias}`}>
                    {this.initSubMenu(opcion.idAccion,listMenu.find(o => o.menu === 'MENU_BUSQUEDA_IZQUIERDA').lista)}                    
                  </NavDropdown> 
        }
      
      });
      //console.log(listMenuIzquiezda);
    }

    initSubMenu(idAccion,list){
      const  {t}=this.props;
      return list.filter(o => o.idaccionPadre === idAccion).sort(function(a, b){ 
          if (a.posicion > b.posicion)  return 1;
          if (a.posicion < b.posicion)  return -1;
          return 0;}).map(opcion => {
                  return <NavDropdown.Item key={opcion.idAccion} href={opcion.path}>{t(`${opcion.nombre}`)}</NavDropdown.Item>                     
                  
      });
    }

    opcionesDerecha(){
      const  {t}=this.props;
      const { listMenu } = this.state;
      let listMenuDerecha = listMenu.find(o => o.menu === 'MENU_BUSQUEDA_DERECHA');
      //console.log(listMenuDerecha);
      return listMenuDerecha.lista.map((opcion)=><Nav.Link key={opcion.idAccion} href={opcion.path}>{t(`${opcion.nombre}`)}</Nav.Link>);
    }

    render(){
        //this.opcionesIzquierda();
        
        return(
          <Navbar collapseOnSelect expand="lg" style={{backgroundColor:"#FF005C"}} variant="ligth">
          <Container>          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">  
              <Nav.Link href="/">Inicio</Nav.Link>
              <Nav.Link href="/contenido/quienes-somos">Quienes Somos</Nav.Link>
              <Nav.Link href="/contenido/nuestra-aplicacion">Nuestra Aplicación</Nav.Link> 
              <Nav.Link href="/contenido/preguntas-frecuentes">Preguntas Frecuentes</Nav.Link> 
              <Nav.Link href="/contenido/terminos-condiciones">Terminos y Condiciones</Nav.Link> 
              <Nav.Link href="/contenido/nuestros-socios">Nuestros Socios</Nav.Link>     
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>
        );}
}

export default withTranslation()(NavCategoria);