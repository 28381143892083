import React, { Component } from 'react'

import logo from '../../asset/images/logo.png';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { withTranslation } from 'react-i18next';

class NotAvailable extends Component {

  reintentar = () =>{                 
    this.props.history.push("/");
  }

  render () {
    return (     
        <Container fluid="md" className="p-5">
        <img src={logo} alt="logo" className="p-1"/>
        <Row className="justify-content-md-center">                
        <Card style={{"width":"25rem"}}>                                
                <Card.Body>
                        <Col className="col text-center">
                            <i className="far fa-times-circle fa-10x" style={{"color":"Tomato"}} ></i>
                            <h2>En este momento la plataforma no se encuentra disponible</h2>                            
                            <p>Favor comuniquese con el administrador de la plataforma si requiere mayor información.</p> 
                            <div className="d-grid gap-2 d-md-block m-3 text-center">                                    
                                <button type="button" value="Entrar" className="btn btn-success mx-1" onClick={()=>this.reintentar()}>Reintentar</button>                                   
                            </div>
                        </Col> 
                </Card.Body>
                </Card>  
        </Row>                                         
        </Container>
    )
  }
}

export default withTranslation()(NotAvailable);