import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../asset/images/logo.png';

import { getParametrosSistemaBySeccion } from '../services/parametroSistemaServ';
import { constantes } from './constantes';
import { withTranslation } from 'react-i18next';
import { menuFooter } from '../test/MenuFooter';

class FooterInformation extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          listContentInformation:[],
          menuListFooter:menuFooter
      };
    }

    componentDidMount() {
      this.listarParametros();         
  }
  
  listarParametros(){
    getParametrosSistemaBySeccion(constantes.variableSeccionFooterInformacion).then(result => {
         let  datos = result.data.data;
         this.setState({ listContentInformation:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
  }

  opcionPadre(){

    const { menuListFooter } = this.state;

    const  {t}=this.props;

    let menu = [];

    menuListFooter.map((opcion,index)=>{
      menu.push(
        <Col key={index}>
                <div className="footer-links">
                  <h3 className="links-title text-vamos-futbol">{t(`${opcion.menu}`)} <i className="fas fa-info-circle"></i></h3>
                  <div className="tabBlock" id="TabBlock-1">
                    <ul className="list-links list-unstyled">
                      {this.opcionHijo(opcion.lista)}
                    </ul>
                  </div>
                </div>
        </Col>
      );
    });

    return menu;
  }

  opcionHijo(list){
    const  {t}=this.props;
    return list.sort(function(a, b){ 
      if (a.posicion > b.posicion)  return 1;
      if (a.posicion < b.posicion)  return -1;
      return 0;}).map(opcion => {
              return <li key={opcion.idAccion} ><a href={opcion.path}><i className="fas fa-chevron-right"></i>{t(`${opcion.nombre}`)}</a></li>              
    });
  }

  render(){

      const { listContentInformation } = this.state;

      const  {t}=this.props;

      let texto = listContentInformation.length>0?listContentInformation.find(o => o.nombreParametro === constantes.footerTexto):null;

      let correo = listContentInformation.length>0?listContentInformation.find(o => o.nombreParametro === constantes.footerCorreo):null;

      let telefono = listContentInformation.length>0?listContentInformation.find(o => o.nombreParametro === constantes.footerTelefono):null;

      let direccion = listContentInformation.length>0?listContentInformation.find(o => o.nombreParametro === constantes.footerDireccion):null;

      return(
        <Container>
            <Row className="p-3">
              <Col>
                <div className="footer-logo">
                  <a href="#"><img src={logo} alt="fotter logo" width="250px" height="100px" /></a>
                </div>
                <div className="footer-content">
                  <div className="email"> <i className="fa fa-envelope"></i>
                    <p>ventas@vamos-futbol.com</p>
                  </div>
                  <div className="phone"> <i className="fa fa-phone"></i>
                    <p>{telefono!=null?telefono.valor:''}</p>
                  </div>
                  <div className="address"> <i className="fa fa-map-marker"></i>
                    <p>Avenida Real de Pereira, Condominio Catalano, Casa 4.</p>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="footer-links">
                  <h3 className="links-title text-vamos-futbol">Información <i className="fas fa-info-circle"></i></h3>
                  <div className="tabBlock" id="TabBlock-1">
                    <ul className="list-links list-unstyled">
                      <li><a href="/contenido/quienes-somos"><i className="fas fa-chevron-right"></i>Quienes Somos</a></li>
                      <li><a href="/contenido/nuestra-aplicacion"><i className="fas fa-chevron-right"></i>Nuestra Aplicación</a></li>
                      <li><a href="/contenido/preguntas-frecuentes"><i className="fas fa-chevron-right"></i>Preguntas Frecuentes</a></li>
                      <li><a href="/contenido/terminos-condiciones"><i className="fas fa-chevron-right"></i>Terminos y Condiciones</a></li>
                      <li><a href="/contenido/nuestros-socios"><i className="fas fa-chevron-right"></i>Nuestros Socios</a></li>
                      <li><a href="/contenido/politicas-privacidad"><i className="fas fa-chevron-right"></i>Póliticas de Privacidad</a></li>
                    </ul>
                  </div>
                </div>
             </Col>
            </Row>
      </Container>
      );
  }
}

export default withTranslation()(FooterInformation);