import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getParametrosSistema(pagination,row,filter){

    try{
        const response = await API.get(`/parametroSistema?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getParametrosSistemaBySeccion(seccion){

    try{
        const response = await API.get(`/parametroSistema/${constantes.canal}/${seccion}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteParametroSistema(parametroSistema){
    try{
        const response = await API.delete(`/parametroSistema/${parametroSistema.idParametroSistema}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addParametroSistema(parametroSistema)
{
    try{
        const response = await API.post(`/parametroSistema`, parametroSistema , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getParametroSistemaById(idParametroSistema)
{
    try{
        const response = await API.get(`/parametroSistema/${idParametroSistema}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}