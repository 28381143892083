import {useEffect,useState} from "react";
import { useTranslation } from 'react-i18next';
import { getContenidoByTipo,contenidoURL } from '../services/contenidoServ';

function Testimonial(){

    const  [listContenido, setListContenido] =useState();
    const  [imageContenido, setImageContenido] =useState();
    const { t } = useTranslation();   

    useEffect(()=>{
        if(listContenido===undefined){
          listarContendido();
        }
        if(imageContenido===undefined){
            obtenerImagen();
          }
    },[]);
    
    const listarContendido =()=>{
      getContenidoByTipo('TESTIMONIOS').then(result => {
           let  datos = result.data.data;         
           setListContenido(datos);
      }).catch((error)=>{
        setListContenido();
       });
    }

    const obtenerImagen =()=>{
        getContenidoByTipo('BANNER_DELIVERY').then(result => {
             let  datos = result.data.data;         
             setImageContenido(datos);
        }).catch((error)=>{
          setListContenido();
         });
      }

    const list = ()=>{
        if(listContenido===undefined)return <></>;
        return listContenido.sort(function(a, b){ 
          if (a.orden > b.orden)  return 1;
          if (a.orden < b.orden)  return -1;
          return 0;}).map((item,index)=>{
            return  <div key={index} className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-3">
                                        <img className="img-fluid rounded-circle" src={contenidoURL(item.idContenido)}  style={{width: "60px",height:"60px"}} alt=""/>
                                        <div className="ml-3">
                                            <h5>{item.alias}</h5>
                                            <i>{item.textoCorto}</i>
                                        </div>
                                    </div>
                                    <p>{item.textoLargo}</p>
                        </div>            
        });    
    }

    return(
       <>
        {
            //  <!-- Testimonial Start -->     
            }
        
            <div className="container-fluid" style={{marginTop:"20px",marginBottom:"20px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 py-5 pr-md-5">
                            <h6 className="text-vamos-futbol font-weight-normal text-uppercase mb-3 pt-5">{t('testimonial.label.tittle')}</h6>
                            <h1 className="mb-4 section-title">{t('testimonial.text.short')}</h1>
                            <div className="owl-carousel testimonial-carousel position-relative pb-5 mb-md-5">
                                {list()}                                
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="d-flex flex-column align-items-center justify-content-center h-100 overflow-hidden">                              
                                {
                                        imageContenido!==undefined?
                                        <img  className="h-100" src={contenidoURL(imageContenido[0].idContenido)} alt=""/>
                                        :''
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //  <!-- Testimonial End -->
            }
       
       </>
    );
}

export default Testimonial;