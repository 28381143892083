import React from "react";

import {getDataOperator,getDataUser,dataDestroy,getLanguage,setLanguage} from '../services/SessionServ';
import { getParametrosSistemaBySeccion } from '../services/parametroSistemaServ';
import { getMonedasDisponibles } from '../services/monedaServ';
import { constantes } from './constantes';

import './headerTop.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {  NavLink,Link } from 'react-router-dom';

import NavDropdown from 'react-bootstrap/NavDropdown'

import { withTranslation,Trans } from 'react-i18next';

import esLogo from '../asset/images/flags/es.png';
import enLogo from '../asset/images/flags/en.png';


import avatarDefault from '../asset/images/default_user.jpg';

class HeaderTop extends React.Component{

    constructor(props) {
          super(props);
          this.cerrarSession = this.cerrarSession.bind(this);
          var datosOperador=null;
          var datosUsuario = null;
          try{
            datosOperador = getDataOperator();
            datosUsuario = getDataUser();
          }catch(e){
            datosOperador=null;
            datosUsuario = null;
          }

          this.state = {
            datosUsuario:datosUsuario,
            datosOperador: datosOperador,
            usuarioLogeado: (datosUsuario!==null && datosOperador!== null ? true : false),
            listMonedas:[],
            language: getLanguage()==='en'?enLogo:esLogo,
            listContentInformation:[],
        };
    }


  componentDidMount() {
      this.listarParametros();    
      this.listarMonedas(100,0,[{parametro:"ESTATUS",valor:"ACTIVO"}]);       
  }

  listarParametros(){
    getParametrosSistemaBySeccion(constantes.variableSeccionFooterInformacion).then(result => {
         let  datos = result.data.data;
         this.setState({ listContentInformation:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
  }

  listarMonedas(pagination,row,filter){
    getMonedasDisponibles().then(result => {
        let  datos = result.data.data;
        this.setState({ listMonedas: datos});
    }).catch((error)=>{
    this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
    });
  }

  cerrarSession = () =>{             
      dataDestroy(); 
      this.setState({ usuarioLogeado: false,  datosUsuario:null,datosOperador: null,});
      this.props.history.push("");
  }

  mostrarMonedas(){
    const { listMonedas  } = this.state;

    if(listMonedas.length===0){
      return;
    }

   //console.log(listMonedas);

   let  lista = listMonedas.map((moneda,index)=>{
    return(
      <NavDropdown.Item  key={index} href="#"><span className="cur_icon">{moneda.descripcion}</span>{` ${moneda.iso_moneda}`}</NavDropdown.Item>
    );
    });

    let moneda = listMonedas[0];

    return (
        <NavDropdown title={moneda.descripcion} id="collasible-nav-dropdown">
        {lista}
        </NavDropdown>
    );
  }

  menuUsuarioNoLogeado(){

    const  {t}=this.props;

    return(<>
              <NavLink to="/registroUsuario" className="nav-link"><i className="fas fa-user-plus"></i><span className="hidden-xs">{t('front.headerTop.registrarme.label')}</span></NavLink>
              <NavLink to="/login" className="nav-link"><i className="fas fa-sign-in-alt"></i><span className="hidden-xs">{t('front.headerTop.login.label')}</span></NavLink>
           </>
    );
  }


  mostrarIdiomas(){
    const { language  } = this.state;
    const  {t}=this.props;
    return (
      <>
        <NavDropdown title={t('front.headerTop.language.label')} id="collasible-nav-dropdown">
            <NavDropdown.Item onClick={()=>this.changeLanguage("es")}  href="#"><span className="cur_icon">{t('front.headerTop.language.espanol.label')}</span>{ getLanguage()==='es'?<i className="fas fa-check"></i>:<></>}</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>this.changeLanguage("en")} href="#"><span className="cur_icon">{t('front.headerTop.language.english.label')}</span>{ getLanguage()==='en'?<i className="fas fa-check"></i>:<></>}</NavDropdown.Item>
        </NavDropdown>
        <img src={language} alt="esLogo" className="flag-img" />
      </>
    );
  }

  changeLanguage(idioma){
    const { i18n  } = this.props; 
    i18n.changeLanguage(idioma);
    setLanguage(idioma);
    this.setState({ language: idioma==='en'?enLogo:esLogo});
  }

  menuUsuarioLogeado(){
    const datosUsuario = getDataUser();
    const  {t}=this.props;
    return(
      <>
        <NavDropdown title={t('front.headerTop.profileUSer.option.bashboard.label')}  id="collasible-nav-dropdown">
          <NavDropdown.Item  as={Link}  to="/usuario">
            <i className="fa fa-sun"></i><span className="hidden-xs"> {t('front.headerTop.profileUSer.option.bashboard.label')}</span>
            </NavDropdown.Item>
            <NavDropdown.Item  as={Link}  to="/usuario/miCuenta">
            <i className="fa fa-user"></i><span className="hidden-xs"> {t('front.headerTop.profileUSer.option.MyProfile.label')}</span>
            </NavDropdown.Item>
            <NavDropdown.Item  as={Link} to="/usuario/favoritos">
              <i className="fa fa-heart"></i><span className="hidden-xs"> {t('front.headerTop.profileUSer.option.MyFavorites.label')}</span>
            </NavDropdown.Item>
            <NavDropdown.Item  as={Link} to="/usuario/pedidos">
            <i className="fa fa-pen-nib"></i><span className="hidden-xs"> {t('front.headerTop.profileUSer.option.orders.label')}</span>
            </NavDropdown.Item>
            <NavDropdown.Item  as={Link} to="/usuario/pedidos-programados">
            <i className="fa fa-clock"></i><span className="hidden-xs"> {t('front.headerTop.profileUSer.option.scheduler.orders.label')}</span>
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.cerrarSession}><i className="fa fa-sign-out-alt"></i><span className="hidden-xs">{t('front.headerTop.profileUSer.option.Logout.label')}</span></NavDropdown.Item>
        </NavDropdown>
        <Nav className="me-auto">
        <img className="rounded-circle" style={{width:"45px",height:"40px",marginLeft:"5px"}} src={datosUsuario.imagePerfilUTF!=null?datosUsuario.imagePerfilUTF:avatarDefault} alt="User" />
        </Nav>
      </>
    );
  }

  render(){

        const { usuarioLogeado,listContentInformation } = this.state;
        const  {t}=this.props;
        let phone = listContentInformation.length>0?listContentInformation.find(o => o.nombreParametro === constantes.footerTelefono).valor:null;
        return(             
                    <Navbar collapseOnSelect expand="lg"  style={{backgroundColor:"#000000"}} variant="dark" >
                      <Container>                          
                                <Navbar.Brand href="/" className="fs-6">Bienvenidos a Vamos Futbol</Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                  <Nav className="me-auto">
                                    <Navbar.Text>
                                      <Trans i18nKey="front.headerTop.brand.contacts.label" phone={phone}>
                                      Contactanos: {{phone}} / Correo: ventas@vamos-futbol.com
                                      </Trans>
                                    </Navbar.Text>
                                  </Nav>
                                  <Nav className="justify-content-end">                         
                                  </Nav>
                                </Navbar.Collapse>                 
                      </Container>                
                    </Navbar>                            
    );}
}


export default withTranslation()(HeaderTop);
