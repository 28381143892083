import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const NotFoundInner =()=>{
        
        return(
            <Container fluid className="p-5">           
            <Row className="justify-content-md-center">                
            <Card>                                
                    <Card.Body>
                            <Col className="col text-center">
                                <i className="far fa-times-circle fa-10x" style={{"color":"Tomato"}} ></i>
                                <h2>Dirección no encontrada</h2>
                                <p>La pagina que intenta acceder no se encuentra ó está temporalmente desabilitada, favor comuniquese con el administrador de la plataforma.</p>                                 
                            </Col> 
                    </Card.Body>
                    </Card>  
            </Row>                                         
        </Container>);
};

export default NotFoundInner;