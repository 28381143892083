import React from "react";

import Carousel from 'react-bootstrap/Carousel';

import { getContenidoByTipo,contenidoURL } from '../services/contenidoServ';
import { withTranslation } from 'react-i18next';

import slider1 from '../asset/images/slider1.jpg';
import slider2 from '../asset/images/slider2.jpg';
import slider3 from '../asset/images/slider3.jpg';

class Slider extends React.Component{

    constructor(props) {
        super(props);
        this.state ={index:0 ,listContenidoSlider:[],};
    }

  componentDidMount() {
      this.listarContendido();         
   }
  
   listarContendido(){
    getContenidoByTipo('BANNER_CARRUSEL').then(result => {
         let  datos = result.data.data;         
         this.setState({ listContenidoSlider:datos});
    }).catch((error)=>{
     this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
     });
    }

    handleSelect (selectedIndex,e){
      this.setState({ index: selectedIndex });
    };

    createItem(listItem){
        const  {t}=this.props;
        return listItem.sort(function(a, b){ 
          if (a.orden > b.orden)  return 1;
          if (a.orden < b.orden)  return -1;
          return 0;}).map((item)=>{
            return <Carousel.Item key={item.idContenido}>
                    <img
                      className="d-block w-100"
                      src={contenidoURL(item.idContenido)}
                      alt={t(`${item.textoCorto}`)}
                    />
                    <Carousel.Caption>
                      <h3>{t(`${item.textoCorto}`)}</h3>
                      <p>
                      {t(`${item.textoLargo}`)}                        
                      </p>
                    </Carousel.Caption>
            </Carousel.Item>
        });
    }


    render(){
        const{index,listContenidoSlider} = this.state;

        let list = this.createItem(listContenidoSlider);

        return(
               <Carousel activeIndex={index} onSelect={(i,e)=>{this.handleSelect(i,e)}}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={slider1}
                      alt={`item.textoCorto`}
                    />
                    <Carousel.Caption>
                      <h3>Para Compartir con los amigos</h3>
                      <p>Armar tus grupos para ordenar las partidas de futbol</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={slider2}
                      alt={`item.textoCorto`}
                    />
                    <Carousel.Caption>
                      <h3>Reserva las canchas deportivas</h3>
                      <p>De forma Facil y agil y con tu método de pago preferido</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={slider3}
                      alt={`item.textoCorto`}
                    />
                    <Carousel.Caption>
                      <h3>Inscribete en Torneos y/o busca retos con otros equipos</h3>
                      <p>Inscribe a tu equipo a torneos y/o busca retos para mejorar la calidad de tus compañeros</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
        );}
}

export default withTranslation()(Slider);