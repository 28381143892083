import Alert from 'react-bootstrap/Alert';

function MensajeAlert(props) {
    return (         
        <div className="col-sm-12 table-responsive p-3">
            <Alert variant={props.variante} className="text-center" >
                {props.mensaje}
            </Alert>
        </div>
    );
}

MensajeAlert.defaultProps = {
    mensaje:"Mensaje Default datos...",
}

export default MensajeAlert;