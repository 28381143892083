import MensajeLoad from "./MensajeLoad";

function TableData(props) {
    return (  
        <>{props.signalLoadData === true && props.listData.lenght!==0 ?             
            <MensajeLoad mensaje={props.mensajeLoad} />         
        :               
            <div className="col-sm-12 table-responsive">                
                    <table className="table table-hover">
                            {props.children}
                    </table>
            </div>
        }
        </>
    );
}

TableData.defaultProps = {
    children:<a>NO DATA HEADER</a>,
    signalLoadData:false,
}

export default TableData;