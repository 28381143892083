import { ActionTypes } from "../constants/action-types";


const initialState = {
    idProducto:0,
    producto:{},
};

export const productoDetalleReducer=(state = initialState,action)=>{
     switch (action.type) { 
        case ActionTypes.FETCH_PRODUCTO_DETALLE:
            return { ...state, idProducto: action.payload};
        case ActionTypes.PRODUCTO_DETALLE:
            return {...state, producto:action.payload};
        default:
            return state;
     }
}