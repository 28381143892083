import React from "react";
import BannerStatic from "./bannerStatic";
import CategoryArea  from "./categoryArea";
import LatestNew  from "./latestNew";
import SaleSpecialContainer from "./SaleSpecialContainer";
import ServiceArea from "./serviceArea";
import Slider  from "./slider";
import SpecialProduct from "./specialProduct";
import Testimonial  from "./testimonial";
import TopSellerProductContainer  from "./topSellerProductContainer";
import FooterNewsletter  from "./footerNewsletter";


export class PrincipalContainer extends React.Component{

    constructor(props) {
        super(props);
    }

    /**
     * 
     *  { this.props.listContenido.some(item =>item === 'TESTIMONIOS')?<Testimonial { ...this.props }/>:<></>}        
          { this.props.listContenido.some(item =>item === 'ULTIMAS_NOTICIAS')?<LatestNew { ...this.props }/>:<></>} 
                     { this.props.listContenido.some(item =>item === 'GRAN_OFERTA')?<SaleSpecialContainer { ...this.props }/>:<></>}       
     * 
     */

    render(){
        return(
          <>
          { this.props.listContenido.some(item =>item === 'BANNER_CARRUSEL')?<Slider { ...this.props }/>:<></>}
          { this.props.listContenido.some(item =>item === 'BANNER_DELIVERY')?<ServiceArea { ...this.props }/>:<></>}
          { this.props.listContenido.some(item =>item === 'BANNER_OFERTA')?<BannerStatic { ...this.props }/>:<></>}      
          { this.props.listContenido.some(item =>item === 'SUSCRIPCION_NOTICIA')?<FooterNewsletter { ...this.props }/>:<></>}
          </>
    );}
}