// menu  busqueda ejemplo canal WEB para la seccion MENU_BUSQUEDA

export const menuBusquedaListTest = [
    {
    menu:"MENU_BUSQUEDA_IZQUIERDA",
    lista:[
    // Opciones  Categorias izquierdo
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/","alias":"INICIO","idAccion":11,"nombre":"Inicio","estatus":"ACTIVO","descripcion":"Inicio","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":0,"esSubMenu":false,"idaccionPadre":0},
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/quienes-somos","alias":"QUIENES_SOMOS","idAccion":11,"nombre":"Quienes Somos","estatus":"ACTIVO","descripcion":"Quienes Somos","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":1,"esSubMenu":false,"idaccionPadre":0},   
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/plataforma-mobile","alias":"PLATAFORMA_MOBILE","idAccion":11,"nombre":"Nuestra aplicación Mobile","estatus":"ACTIVO","descripcion":"Nuestra aplicación Mobile","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":2,"esSubMenu":false,"idaccionPadre":0},
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/preguntas-frecuentes","alias":"PREGUNTAS_FRECUENTES","idAccion":11,"nombre":"Preguntas Frecuentes","estatus":"ACTIVO","descripcion":"Preguntas Frecuentes","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":3,"esSubMenu":false,"idaccionPadre":0},    
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/politicas-privacidad","alias":"POLITICAS_PRVACIDAD","idAccion":22,"nombre":"Póliticas de privacidad","estatus":"ACTIVO","descripcion":"Póliticas de privacidad","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":4,"esSubMenu":false,"idaccionPadre":0},
    {"activo":false,"classNameIcon":"fas fa-sliders-h","path":"/contenido/nuestros-socios","alias":"NUESTROS_SOCIOS","idAccion":23,"nombre":"Nuestros socios","estatus":"ACTIVO","descripcion":"Nuestros socios","menu":{"idMenu":2,"nombre":null,"estatus":null,"descripcion":null,"tipoMenu":null,"canal":null},"posicion":5,"esSubMenu":false,"idaccionPadre":0},            
    ]
    },
    {
        menu:"MENU_BUSQUEDA_DERECHA",
        lista:[
        // Opciones TOP menu derecho   
       
        ]
    },  
];