import { ActionTypes } from "../constants/action-types";


const initialState = {
    listaPedidoProgramado:undefined,
    pedidoProgramado:undefined,
};

export const pedidoProgramadoReducer=(state = initialState,action)=>{
    switch (action.type) {
       case ActionTypes.ADD_PEDIDO_PROGRAMADO:             
           return {...state, pedidoProgramado:action.payload};  
       case ActionTypes.REMOVE_PEDIDO_PROGRAMADO:
            return {...state, pedidoProgramado:action.payload};
       case ActionTypes.FETCH_LIST_PEDIDO_PROGRAMADO:
           return { ...state, listaPedidoProgramado: undefined, pedidoProgramado:undefined};
       case ActionTypes.LIST_PEDIDO_PROGRAMADO:
            return { ...state, listaPedidoProgramado:action.payload, pedidoProgramado:undefined};
       default:
           return state;
    }
}