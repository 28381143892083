import { ActionTypes } from "../constants/action-types";


const initialState = {
    paso:0,
    origenProceso:"",
    pedido:undefined,
    estadoProcesoPedido:undefined
};

export const procesarPagoReducer=(state = initialState,action)=>{
    switch (action.type) { 
        case ActionTypes.PASO_PROCESAR_PAGO:
           return { ...state, paso: action.paso , origenProceso:action.origenProceso, pedido:undefined,estadoProcesoPedido:undefined};
        case ActionTypes.INIT_PROCESAR_PAGO:
            return { ...state, paso: action.paso ,pedido:action.payload,estadoProcesoPedido:undefined};
        case ActionTypes.ESTADO_PROCESO_PAGO:
            return { ...state, paso: action.paso ,estadoProcesoPedido:action.payload.estatus,pedido:action.payload};
        case ActionTypes.FIN_PROCESO_PAGO:
            return { ...initialState};
        default:
           return state;
    }
}