import React from "react";

export class SocialNetwork extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
        <div className="d-inline">
            <li className="social-network fb"><a title="Connect us on Facebook" href="#"><i className="fa fa-facebook"></i></a></li>
            <li className="social-network googleplus"><a title="Connect us on Google+" href="#"><i className="fa fa-google-plus"></i></a></li>
            <li className="social-network tw"><a title="Connect us on Twitter" href="#"><i className="fa fa-twitter"></i></a></li>
            <li className="social-network linkedin"><a title="Connect us on Linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>
            <li className="social-network rss"><a title="Connect us on Instagram" href="#"><i className="fa fa-rss"></i></a></li>
            <li className="social-network instagram"><a title="Connect us on Instagram" href="#"><i className="fa fa-instagram"></i></a></li>
        </div>
    );}
}