import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import {getI18n } from "./services/traduccionServ";
import { setI18n } from "./services/SessionServ";

let initial18n = async () => {
  try{
      var esData = null;
      var enData = null;
      setI18n(undefined);
      if (typeof window !== 'undefined'){ esData  = (await getI18n('es'));}
      if (typeof window !== 'undefined') { enData = (await getI18n('en')); }
      return {esData, enData };
  }
  catch(error){
      window.location.href = "/platform-service.html";
  }
};

let importI18n = async () =>{
  if (typeof window !== 'undefined') {
     await import('./i18n');  
    // console.log("test index 1");
  }
}

initial18n().then((data)=> { 
  var resources = {
      es:data.esData.data,
      en:data.enData.data
  };
 // console.log("getTokens resources",resources);
  setI18n(resources);
  importI18n().then(()=>{
    //console.log("test index 2");
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
  });
});






