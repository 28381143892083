import { useState, useEffect } from 'react';

import Alert from 'react-bootstrap/Alert';

function Mensaje(props) {
    useEffect(() => { 
        if(props.variant!=="danger"){
            let time =  2000;     
            setTimeout(() => {
                props.showMessage(false);
            }, time);
        }
      }, [props.show]);

    const message=()=>{
        if(props.variant==="danger"){
            return(
                <Alert show={props.show} variant="danger" onClose={() => props.showMessage(false)} className="alert-fixed mx-auto" dismissible>
                    <Alert.Heading>Ocurrio un error</Alert.Heading>
                    <p>
                     {props.message}
                    </p>
                </Alert>
            );
        }
        else{
            return(<Alert show={props.show} variant={props.variant} className="alert-fixed mx-auto">
            {props.message}
             </Alert>);
        }
    }

    return (
        <>
          {message()}
        </>       
      
    );
}

Mensaje.defaultProps = {
    variant: 'success',
    message:'text default'
}

export default Mensaje;
