import React from "react";
import { LogoSite } from "./logoSite";
import { ImageApp } from "./imageApp";
import { ImageStoreMobile} from "./imageStoreMobile";
import TopCartContainer from "./topCartContainer";
import  TopSearch  from "./topSearch";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class HeaderBottom extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
          const url = window.location.href;
        return(
            <Container className="pt-5 pb-5">
              <Row>
                <Col className="text-center m-r-2 pb-2" style={{display:"flex", justifyContent:"center"}}  sm>                     
                  <LogoSite/>                  
                </Col>
                <Col  sm={2}>
                  {url.includes("contenido")?
                     <></>
                    : <ImageApp/>}   
                                  
                </Col>                                
                <Col sm={2} > 
                    <ImageStoreMobile/>                                     
                </Col>
              </Row>
            </Container>
    );}
}