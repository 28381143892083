import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getContenidos(pagination,row,filter){
    try{
        const response = await API.get(`/contenido?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getContenidoByTipo(tipo){
    try{
        const response = await API.get(`/contenido/${constantes.canal}/${tipo}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}


export async function updateEstatusContenido(contenido){
    try{
        const response = await API.get(`/contenido/estatus/${contenido.idContenido}/${contenido.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteContenido(contenido){
    try{
        const response = await API.delete(`/contenido/${contenido.idContenido}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addContenido(contenido)
{
    try{
        const response = await API.post(`/contenido`, contenido , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getContenidoById(idContenido)
{
    try{
        const response = await API.get(`/contenido/${idContenido}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function uploadBinarioContenido(idContenido,file){
    try{
        const headers = {
            'Content-Type': "multipart/form-data",
        };
        const response = await API.post(`/contenido/${idContenido}/fileUpload/`, file ,headers);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export function contenidoURL(idContenido){    
        const response = `../api/contenido/${idContenido}/media`;
        return response;
}