import React from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import { multimediaProductoURL } from '../services/multimediaServ';
import imageDefaultProduct  from '../asset/images/large-default-product.jpg';
import { getProductosByTipoBusqueda } from "../services/productoServ";
import './specialProduct.css';

class SpecialProduct extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          listaProductos:undefined,
          cargando:true,
          mensaje:true,
          textoMensaje:'',
          variante:'',
      };   
    }

    componentDidMount()
    {
      this.cargarProductosEspeciales(); 
   }

   onImageError(e) {
    e.target.src = imageDefaultProduct;
    }

    cargarProductosEspeciales(){
      let pagination=0;
      let row=100;
      let filter=[{parametro:"TIPO_BUSQUEDA",valor:"PRODUCTOS_ESPECIALES"}];
      getProductosByTipoBusqueda(pagination,row,filter).then(result => {
            let  datos = result.data.data;
            this.setState({ listaProductos: [...datos], cargando:false  });
      }).catch((error)=>{
        this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
        });
    }

    goDetalle(producto){
      this.props.history.push(`/producto/detalle/${producto.idProducto}`);
    }

    render()
    {
    const  {t}=this.props;
    const { listaProductos} = this.state;
    return( 
      <Container>
        <div className="special-products">
          <div className="page-header">
            <h2>{t('front.specialProducts.tittle.label')}</h2>
          </div>
          <div className="special-products-pro">
            <Container>
              <Row className="pt-1" xl={3} lg={3} ms={3}>
                {
                  listaProductos!==undefined?
                  listaProductos.map((dat,idx)=>{
                    return(
                      <Col >
                                    <Card style={{margin:"2px"}}>
                                              <Card.Img variant="top" style={{width:"5wh",height:"30vh"}} src={multimediaProductoURL(dat.idProducto)} onError={this.onImageError} />
                                              <Card.Body>
                                                <Card.Title>{dat.nombre}</Card.Title>
                                                <Card.Text>
                                                 {dat.descripcion}
                                                </Card.Text>
                                                <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver detalle..</Button>
                                              </Card.Body>
                                            </Card>
                      </Col>
                    );
                  })
                  :
                  <>Cargando...</>
                }
              </Row>  
            </Container>
          </div>
        </div>
      </Container>
    );}
}

export default withTranslation()(withRouter(SpecialProduct));