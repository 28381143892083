import {constantes}  from '../components/constantes';
import {generateString} from '../components/Utils';

const mobStorage = sessionStorage;

export function setDataUser(usuario)
{
    let datos  = { usuario:{idUsuario:usuario.id,correo:usuario.correo,imagePerfilUTF:usuario.imagePerfilUTF } };
    mobStorage.setItem(constantes.sessionUser, JSON.stringify(datos));
}

export function setDataOperator(operator)
{
    let datos  = { operador:operator };
    mobStorage.setItem(constantes.sessionOperador, JSON.stringify(datos));
}

export function setDataToken(data)
{
    let datos  = { token:data.access_token ,tokenType:data.token_type, expireIn:data.expires_in };
    mobStorage.setItem(constantes.sessionToken, JSON.stringify(datos));
}

export function getDataUser()
{
    let datos  = JSON.parse(mobStorage.getItem(constantes.sessionUser));
    return datos.usuario;
}

export function getDataOperator()
{
    let datos  = JSON.parse(mobStorage.getItem(constantes.sessionOperador));
    return datos.operador;
}

export function checkDataSession()
{
    try{
        let datoUser  = getDataUser();
        let datoOperator  = getDataOperator();       
        return true;
    }catch(e){
        return false;
    }
}

export function dataDestroyOperator()
{
    mobStorage.removeItem(constantes.sessionOperador);
}

export function getDataToken()
{
    let datos  = JSON.parse(mobStorage.getItem(constantes.sessionToken));
    return datos;
}

export function dataDestroyToken()
{
    mobStorage.removeItem(constantes.sessionToken);
}

export function dataDestroyUser()
{
    mobStorage.removeItem(constantes.sessionUser);
}

export function dataDestroy(){
    dataDestroyToken();
    dataDestroyOperator();
    dataDestroyUser();
}

export function generarSessionTemporal(){
    let datos  = JSON.parse(mobStorage.getItem(constantes.sessionTokenTemp));
    //console.log("TEMP::",datos)
    var currentDate = new Date().toJSON().slice(0, 10);
    let generarDato=datos===null || datos===undefined?true:(datos!==undefined && datos!==null && currentDate!==datos.date?true:false);

    if(generarDato){
        //console.log("generarDato",generarDato);
        let ramdom=generateString(80);
        //let ramdom='UHVExInonIYqSvZQcy76MzPy16l5o7bP3pG6JVjgyIWr7ehkFCYevhGAjhTViBMiGlq6WykP1v3It60Q';
        const initTemp={
            sessionTemp:ramdom,    
            date: currentDate,       
        };
        mobStorage.setItem(constantes.sessionTokenTemp, JSON.stringify(initTemp));
        datos=initTemp;
    }

    return datos.sessionTemp;
}


export function setI18n(data)
{
    let datos  = data ;
    mobStorage.setItem(constantes.sessionI18n, datos!==undefined?JSON.stringify(datos):null);
}

export function getI18n()
{
    let datos  = mobStorage.getItem(constantes.sessionI18n)!==undefined?JSON.parse(mobStorage.getItem(constantes.sessionI18n)):undefined;
    return datos;
}


export function setLanguage(language)
{
    let datos  = language ;
    mobStorage.setItem(constantes.sessionI18nLanguague, datos!==undefined?JSON.stringify(datos):null);
}

export function getLanguage()
{
    let datos  = mobStorage.getItem(constantes.sessionI18nLanguague);
    if(datos===undefined || datos===null){
        setLanguage(constantes.sessionI18nLanguagueDefault);
        datos = constantes.sessionI18nLanguagueDefault;
        //console.log("1...datos",datos);
    }else{
        datos = JSON.parse(mobStorage.getItem(constantes.sessionI18nLanguague));
        //console.log("2...datos",datos);
    }
    return datos;
}