import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getMultimedias(pagination,row,filter){
    try{
        const response = await API.get(`/multimedia?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function updateEstatusMultimedia(multimedia){
    try{
        const response = await API.get(`/multimedia/estatus/${multimedia.idMultimedia}/${multimedia.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteMultimedia(multimedia){
    try{
        const response = await API.delete(`/multimedia/${multimedia.idMultimedia}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addMultimedia(multimedia)
{
    try{
        const response = await API.post(`/multimedia`, multimedia , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getMultimediaById(idMultimedia)
{
    try{
        const response = await API.get(`/multimedia/${idMultimedia}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function uploadImagenProducto(idMultimedia,file){
    try{
        const headers = {
            'Content-Type': "multipart/form-data",
        };
        const response = await API.post(`/multimedia/${idMultimedia}/fileUpload/`, file ,headers);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export function multimediaURL(idMultimedia){    
        const pathname = window.location.pathname;
        const protocol = window.location.protocol;
        const hostname = window.location.hostname;  
        let url = constantes.url;
        url = url.replaceAll("localhost",hostname);
        //url = url.replaceAll("9090",protocol);
        const response = `${url}/multimedia/${idMultimedia}/media/MULTIMEDIA`;
        //console.log("multimediaURL",response);
        return response;
}

export function multimediaProductoURL(idProducto){  
    const pathname = window.location.pathname;
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;  
    let url = constantes.url;
    url = url.replaceAll("localhost",hostname);
    
    const response = `${url}/multimedia/${idProducto}/media/PRODUCTO`;
    //console.log("multimediaProductoURL",response);
    return response;
}