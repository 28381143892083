import React from "react";

import { SocialNetwork } from "./socialNetwork";
import Subscribe from "./subscribe";

import './footerNewsletter.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';

class FooterNewsletter extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){

        return(
            <div className="footer-newsletter">
                <Container>
                  <Row>
                    <Col>
                        <Subscribe/>
                    </Col>
                  </Row>
                </Container>
            </div>
    );}
}

export default withTranslation()(FooterNewsletter);