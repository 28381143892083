

export function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] === variable){return pair[1];}
         }
         return(false);
}

export function optionsSelectV2(list,activarSeleccione,nombre,codigo) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg[codigo]} key={reg[codigo].toString()}>                    
                {reg[nombre]}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }

}

export function optionsSelectV3(list,activarSeleccione,funcion,codigo) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg[codigo]} key={reg[codigo].toString()}>                    
                { funcion(reg,index) }
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }

}


export function optionsSelectV4(list, activarSeleccione, funcion, functionCode) {

    let rows = list.map((reg, index) => {
        let code = functionCode(reg, index);
        return (
            <option value={code} key={code}>
                {funcion(reg, index)}
            </option>
        );
    });

    if (activarSeleccione) {
        return [<option key={-12}></option>, ...rows];
    } else {
        return rows
    }

}

export function optionsSelectV5(list, activarSeleccione, funcion, functionCode,funtionDisabled) {

    let rows = list.map((reg, index) => {
        let code = functionCode(reg, index);
        let desabilitar = funtionDisabled(reg, index);
        return (
            <option disabled={desabilitar} value={code} key={code}>
                {funcion(reg, index)}
            </option>
        );
    });

    if (activarSeleccione) {
        return [<option key={-12}></option>, ...rows];
    } else {
        return rows
    }

}

export function optionsSelect(list,activarSeleccione) {

        let rows = list.map((reg,index) => {
            return (                
                <option value={reg.codigo} key={reg.codigo.toString()}>                    
                    {reg.nombre}
                </option>
            );
        });

        if(activarSeleccione){
            return [<option key="-1"></option>, ...rows];
        }else{
           return rows 
        }

}

export function optionsSelectUsuario(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idUsuario} key={reg.idUsuario.toString()}>                    
                {reg.correo}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }

}

export function optionsSelectPersona(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idPersona} key={reg.idPersona.toString()}>                    
                {reg.nombres} {reg.apellidos} / {reg.tipoIdentificacion} {reg.numeroIdentificacion}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

export function optionsSelectSistema(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idSistema} key={reg.idSistema.toString()}>                    
                {reg.nombre}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

export function optionsSelectCanal(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idCanal} key={reg.nombre.toString()}>                    
                {reg.nombre}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

export function optionsSelectDispositivo(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idDispositivo} key={reg.nombre.toString()}>                    
                {reg.nombre}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

export function filterQueryString(list) {

    let filter = ""; 
    list.map((reg,index) => {
        filter=filter+`${reg.parametro}=${reg.valor}&`
    });
    
    return filter;

}

export function optionsSelectMenu(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idMenu} key={reg.idMenu.toString()}>                    
                {reg.nombre}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

export function optionsSelectAccion(list,activarSeleccione) {

    let rows = list.map((reg,index) => {
        return (                
            <option value={reg.idAccion} key={reg.idAccion.toString()}>                    
                {reg.nombre}
            </option>
        );
    });

    if(activarSeleccione){
        return [<option></option>, ...rows];
    }else{
       return rows 
    }
}

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export function binToText(bin) {
    return bin.replace(/\s*[01]{8}\s*/g, function(bin) {
        return String.fromCharCode(parseInt(bin, 2))
    });
}

export function textToBin(str, spaceSeparatedOctets) {
    return str.replace(/[\s\S]/g, function(str) {
      str = zeroPad(str.charCodeAt().toString(2));
      return !1 == spaceSeparatedOctets ? str : str + " "
    })
}

export function zeroPad(num) {
    return "00000000".slice(String(num).length) + num
}

export function convertDateTimeToFormatAPI(date){
    return date === null ? "" : date.getFullYear() + "-"+`${(date.getMonth()+1)}`.padStart(2, '0') +"-"+ `${date.getDate()}`.padStart(2, '0') +" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
}

export function convertDateToFormatAPI(date){
    return date === null ? "" : date.getFullYear() + "-"+ `${(date.getMonth()+1)}`.padStart(2, '0')+"-"+`${date.getDate()}`.padStart(2, '0')+" 00:00:00";
}

export function convertDateAPIToOnlyDDMMYYY(stringDate){
    if(stringDate===undefined || stringDate===null || stringDate==='') return '';
    let date = new Date(`${stringDate}`);
    let dateOutput= date === null ? "" : date.getFullYear() + "-"+ `${(date.getMonth()+1)}`.padStart(2, '0') +"-"+ `${date.getDate()}`.padStart(2, '0');
    return dateOutput;
}

export function convertDateAPIToOnlyDDMMYYYHHMMSS(stringDate){
    if(stringDate===undefined || stringDate===null || stringDate==='') return '';
    let date = new Date(`${stringDate}`);
    let dateOutput= date === null ? "" : date.getFullYear() + "-"+ `${(date.getMonth()+1)}`.padStart(2, '0') +"-"+ `${date.getDate()}`.padStart(2, '0')+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
    return dateOutput;
}

export function subtractYears(date, years) {
    const dateCopy = new Date(date);  
    dateCopy.setFullYear(date.getFullYear() - years);
    return dateCopy;
}

export function formatNumber(number){
    return parseFloat(number).toFixed(2);
}