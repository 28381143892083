import React from "react";
import logo from '../asset/images/logo.png';

export class LogoSite extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <img alt="e-commerce" src={logo} width="300px" height="120px" />
        );}
}