import React from "react";
import imageStore from '../asset/images/google-play-app-store-apple-apple_v1.png';
import "./imageStoreMobile.css";


export class ImageStoreMobile extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <img alt="vamos-futbol-store" className="imageStoreMobile_float" src={imageStore} width="170px" height="80px" />
        );}
}