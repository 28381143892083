import { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {  getcontenidoHtmlByAliasAndISO} from '../services/contenidoHtmlServ';
import { binToText } from "./Utils";
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import NotFoundContent from "../pages/notFound/NotFoundContent";
import { getLanguage } from "../services/SessionServ";
import Spinner from 'react-bootstrap/Spinner';

const ContenidoMaster =()=>{

        const [contenidoHtml, setContenidoHtml] = useState();
        const [datos, setDatos] = useState(undefined);
        const { t, i18n } = useTranslation();
        const [primeraCarga, setPrimeraCarga] = useState(true);

        useEffect(() => {             
                if(datos!==undefined && datos!==null){
                        const contentBlock = binToText(datos.contenidoBinario);                     
                        setContenidoHtml(contentBlock);
                } 
                if(datos===undefined)
                {             
                    consultarContenido();
                }   
        }, [datos]);

        useEffect(() => { 
              if(datos!==undefined && primeraCarga===false){
                setDatos(undefined); 
              }
        }, [i18n.language]);

        const consultarContenido = ()=>{
                let iso = i18n.language;
                let bus= window.location.pathname.split("/")[2];            
                getcontenidoHtmlByAliasAndISO(bus,iso).then(result => {
                        let  datos =  result.data;                                                 
                        setDatos(datos.contenidoBinario!==null && datos.contenidoBinario!==undefined?datos:null);
                        setPrimeraCarga(false);
                });                
        } 
        
        const mostrarDatos=()=>{
                if(datos===undefined)
                {
                        return(
                                <>
                                    <Spinner animation="border" role="status">
                                    </Spinner>
                                    <span>{t('front.general.spinner.text')}</span>
                                </>
                        );
                }else{
                        return contenidoHtml!==undefined && contenidoHtml!==null? parse(contenidoHtml):<NotFoundContent/>;
                }
        }

        return(
        <Container fluid className="p-4">           
            <Row>                
            <Card>                                
                    <Card.Body>
                            <Col>                               
                               {mostrarDatos()}                                 
                            </Col> 
                    </Card.Body>
                    </Card>  
            </Row>                                         
        </Container>);
};

export default ContenidoMaster;
