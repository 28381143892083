import { combineReducers } from 'redux';
import { carritoCompraReducer } from './carritoCompraReducer';
import { productoDetalleReducer } from './productoDetalleReducer';
import { procesarPagoReducer } from './procesarPagoReducer';
import { loginReducer } from './loginReducer';
import { favoritoReducer } from './favoritoReducer';
import { pedidoProgramadoReducer } from './pedidoProgramadoReducer';

const reducers =combineReducers({
    carrito:carritoCompraReducer,
    productoDetalle:productoDetalleReducer,
    procesoPago:procesarPagoReducer,
    login:loginReducer,
    favorito:favoritoReducer,
    pedidoProgramado:pedidoProgramadoReducer,
});

export default reducers;