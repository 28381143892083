import React from "react";
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';
import MensajeLoad from "../../components/MensajeLoad";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MensajeAlert from '../../components/MensajeAlert';
import { registrarUsuario,activarUsuario } from "../../services/usuarioServ";
import { withTranslation } from 'react-i18next';

class RegistroUsuario extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            mensajeError: "",
            cargando: false,
            pasoProceso:1,
            usuario:{correo:"",password:"",repassword:"",token:""},
            desableButton:false,
            validated: false,
            validatedFormToken:false,
            mensaje:false,
            variante:"",
        };
    }

    myChangeHandler = (event) => {
        const { usuario } = this.state;   
        let nam = event.target.name;
        let val = event.target.value;
        usuario[nam]=val;
        this.setState({usuario: usuario});
    }

    redesSociales(){
        const  {t}=this.props;
        return(
            <>
                <div className="right">
                    <span className="loginwith">Registrarme con <br />Redes Sociales</span>
                    
                    <button className="social-signin facebook">Facebook</button>
                    <button className="social-signin twitter">Twitter</button>
                    <button className="social-signin google">Google+</button>
                </div>
                <div className="or">O</div>
            </>
        );
    }

    registrarUsuario(event){
        const {usuario} = this.state;
        const  {t}=this.props;
        this.setState({ desableButton: true ,cargando: true,validated: false ,mensajeError:"",mensaje:false});
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ validated: true,desableButton: false ,cargando: false, validatedFormToken: true,mensaje:false });
            event.preventDefault();
            event.stopPropagation();
        }else{
            if(usuario.password !== usuario.repassword){
                this.setState({ validated: false,desableButton: false ,cargando: false, 
                    validatedFormToken: true,mensaje:true,
                    mensajeError:`La confirmación del password no coincide, por favor valide los datos.` , variante:"danger" });
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            registrarUsuario(usuario).then(result => {
                console.log(result.data);
                let paso = result.data.estatus ==="PENDIENTE_ACTIVACION" ? 2:3;
                this.setState({ pasoProceso: paso,validated: false,desableButton: false ,cargando: false, validatedFormToken: false});
            }).catch((error)=>{
               this.setState({ validated: false,desableButton: false ,cargando: false, validatedFormToken: false , mensaje:true , mensajeError:`Ocurrio un error en el proceso por favor valide los datos ingresados` , variante:"danger" });
            });            
            event.preventDefault();
        }        
    }

    eventValidarToken(event){
        const {usuario} = this.state;
        const  {t}=this.props;
        const form = event.currentTarget;
        this.setState({ desableButton: true ,cargando: true,validated: false ,mensajeError:"",mensaje:false});
        if (form.checkValidity() === false) {
            this.setState({ validated: true,desableButton: false ,cargando: false, validatedFormToken: true,mensaje:false });
            event.preventDefault();
            event.stopPropagation();
        }else{
            activarUsuario(usuario).then(result => {
                console.log(result.data);
                if(result.data.estatus ==="ACTIVO"){
                    this.setState({ pasoProceso: 3,validated: false,desableButton: false ,cargando: false, validatedFormToken: false});
                }
                else{
                    this.setState({ cargando:false ,desableButton: false , mensaje:true , mensajeError:`Ocurrio un error en el proceso por favor valide los datos ingresados` , variante:"danger" });
                }               
            }).catch((error)=>{
               this.setState({ cargando:false , mensaje:true,desableButton: false  , mensajeError:`Ocurrio un error en el proceso por favor valide los datos ingresados` , variante:"danger" });
            });
            event.preventDefault();
        }        
    }

    paso1DatosBasicos(){
        const {usuario,validated,mensajeError,variante} = this.state;
        const  {t}=this.props;
        return(
            <>
            <h1>{t('front.registerUser.register.title.text')}</h1>  
            { mensajeError!==""?<MensajeAlert mensaje={mensajeError} variante={variante} />:<></> }  
            <Form className="p-3 text-center" noValidate validated={validated} onSubmit={(event)=>{this.registrarUsuario(event)}} >                  
                <FloatingLabel  controlId="floatingInputCorreo"  label={t('front.registerUser.email.label.text')}  className="mb-3" >
                    <Form.Control required type="email" name="correo" placeholder="Correo" value={usuario.correo} onChange={this.myChangeHandler} />
                </FloatingLabel>
                <FloatingLabel  controlId="floatingInputPass"  label={t('front.registerUser.password.label.text')}  className="mb-3" >
                    <Form.Control required type="password" name="password" placeholder="Password" value={usuario.password} onChange={this.myChangeHandler} />
                </FloatingLabel>  
                <FloatingLabel  controlId="floatingInputRepass"  label={t('front.registerUser.passwordConfirm.label.text')}  className="mb-3" >
                    <Form.Control required type="password" name="repassword" placeholder="Confirmar Password" value={usuario.repassword} onChange={this.myChangeHandler} />
                </FloatingLabel> 
                <div className="d-grid gap-2 d-md-block m-3 text-center">                                    
                    <button type="button" className="btn btn-outline-danger mx-1"  onClick={()=>{ this.props.history.push("/"); }}><i className="fas fa-chevron-left"></i>{t('front.registerUser.back.button.label')}</button>                         
                    <button type="submit" value="Entrar" className="btn btn-success mx-1">{t('front.registerUser.register.button.label')}</button> 
                </div>                             
            </Form>            
            </>
        );
    }

    paso2ValidarCorreo(){
        const {usuario,desableButton,validatedFormToken,variante,mensajeError} = this.state; 
        const  {t}=this.props;      
        return(
            <>
                <h1>Validar Correo</h1>  
                { mensajeError!==""?<MensajeAlert mensaje={mensajeError} variante={variante} />:<></> }  
                <Form className="p-3 text-center" noValidate validated={validatedFormToken} onSubmit={(event)=>{this.eventValidarToken(event)}} >                  
                    <FloatingLabel  controlId="floatingInputCorreo"  label="Correo eletronico"  className="mb-3" >
                        <Form.Control required type="email" readOnly name="correo" placeholder="Correo" value={usuario.correo} onChange={this.myChangeHandler} />
                    </FloatingLabel>
                    <FloatingLabel  controlId="floatingInputToken"  label="Contraseña"  className="mb-3" >
                        <Form.Control required type="text" name="token" placeholder="Token" value={usuario.token} maxLength="6" onChange={this.myChangeHandler} />            
                    </FloatingLabel>  
                    <div className="d-grid gap-2 d-md-block m-3 text-center">                                    
                        <button type="submit" value="Entrar" className="btn btn-success mx-1">Validar</button> 
                        <button type="button" className="btn btn-outline-success ms-1"  onClick={()=>{this.setState({pasoProceso: 1})}}><i className="fas fa-chevron-left"></i>Regresar</button>                         
                    </div>                             
                </Form>
             </>            
        );
    }

    mostrarMensaje(){
        const {mensajeError,variante} = this.state;
        const  {t}=this.props;
        return(
            <div className={`alert alert-${variante} p-3`} style={{"margin":"10px"}} role="alert">
                {mensajeError}
            </div>
        );
    }

    mensajeCarga(){
        const  {t}=this.props;
        return (
            <MensajeLoad mensaje="Registrando Datos..." />
        );
    }

    mostrarPaso(){
        const {pasoProceso} = this.state;
        const  {t}=this.props;
        switch(pasoProceso){
            case 1:
            return this.paso1DatosBasicos();
            case 2:
                return this.paso2ValidarCorreo();
            case 3:
                return this.paso3Resultado();
            default:
            return this.paso1DatosBasicos();
        } 
    }


    paso3Resultado(){
        const {usuario} = this.state;    
        const  {t}=this.props;   
        return(
            <>
                <h1>Bienvenido</h1>  
                <p>Ya puede ingresar a la plataforma</p>                 
                <h1>{usuario.correo}</h1>                 
                <button type="button" value="Entrar" onClick={()=>{this.props.history.push("/")}} className="btn btn-success mx-1">Inicio</button>                                        
            </>            
        );
    }

    render(){
        const {cargando} = this.state;
        return(
        <Container fluid="md" className="p-5">
                <img src={logo} alt="logo" className="p-1"/>
                <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                        <Card.Img variant="top" src={imageLogin} />
                        <Card.Body>
                            <Col className="col text-center">
                                {this.mostrarPaso()}
                                {cargando?this.mensajeCarga():<></>}  
                            </Col> 
                        </Card.Body>
                    </Card>  
                </Row>                                         
        </Container>
    );}
}

export default withTranslation()(RegistroUsuario);