import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getProductos(pagination,row,filter){
    try{
        const response = await API.get(`/producto?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}

export async function updateEstatusProducto(producto){
    try{
        const response = await API.get(`/producto/estatus/${producto.idProducto}/${producto.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteProducto(producto){
    try{
        const response = await API.delete(`/producto/${producto.idProducto}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addProducto(producto)
{
    try{
        const response = await API.post(`/producto`, producto , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getProductoById(idProducto)
{
    try{
        const response = await API.get(`/producto/${idProducto}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        return error;
    }
}

export async function getFavoritos(pagination,row,filter){
    try{
        const response = await API.get(`/producto/favorito?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}


export async function getProductosSearch(pagination,row,filter){
    try{
        const response = await API.get(`/producto/search?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}


export async function getProductosByTipoBusqueda(pagination,row,filter){
    try{
        const response = await API.get(`/producto/tipoBusqueda?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}