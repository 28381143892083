import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getCarritoCompras(pagination,row,filter){   
    try{
        const response = await API.get(`/carritoCompra?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){      
        return  error;
    }
}

export async function updateEstatusCarritoCompra(carritoCompra){
    try{
        const response = await API.get(`/carritoCompra/estatus/${carritoCompra.idCarritoCompra}/${carritoCompra.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        return error;        
    }
}

export async function  deleteCarritoCompra(carritoCompra){
    try{
        const response = await API.delete(`/carritoCompra/${carritoCompra.idCarritoCompra}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        return error;
    }
}

export async function addCarritoCompra(carritoCompra)
{
    try{
        const response = await API.post(`/carritoCompra`, carritoCompra , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){        
        return error;
    }
}

export async function getCarritoCompraById(idCarritoCompra)
{
    try{
        const response = await API.get(`/carritocompra/${idCarritoCompra}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getBolsaCompras(token){   
     try{                  
         const response = await API.get(`/carritoCompra/bolsa/${token}`, constantes.axiosConfig);
         if(response.data.estatus!==constantes.estatusOKAServ){
             throw new Error(response.data.errores[0].mensajeError);
         }
         return response;
     } catch( error ){        
         return  error;      
     }
 }

 export async function updateSesionCorreo(carritoCompra)
{
    try{
        const response = await API.post(`/carritoCompra/sesioncorreo`, carritoCompra , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){        
        return error;
    }
}

export async function emulateBolsaCompra(listCarritoCompra)
{
    try{
        const response = await API.post(`/carritoCompra/emulateBolsaCompra`, listCarritoCompra , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}
