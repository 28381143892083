import Site from "./Site";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import Login from "./pages/login/Login";
import OlvidoClave from "./pages/olvidoClave/OlvidoClave";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import SeleccionOperador  from "./pages/seleccionOperador/SeleccionOperador"; 
import NotFound from "./pages/notFound/NotFound";
import RegistroUsuario from "./pages/registroUsuario/RegistroUsuario";
import NotAvailable from './pages/notAvailable/NotAvailable';
import '../src/asset/css/all.css';

 /**
               <Route exact path='/login' component={Login} />
              <Route exact path='/olvidoClave' component={OlvidoClave} />
              <Route exact path='/registroUsuario' component={RegistroUsuario} />
              <Route exact path='/resetPassword' component={ResetPassword} />            
              <Route exact path='/seleccionOperador' component={SeleccionOperador} /> 
              */   

function  App() {
    return (
      <>     
        <BrowserRouter>
            <Switch>    
              <Route exact path='/no-available' component={NotAvailable} />
              <Route exact path='*' component={Site} />
              <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
      </>
    );
}



export default App;
