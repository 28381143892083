import React from "react";
import { withRouter } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { multimediaProductoURL } from '../services/multimediaServ';
import imageDefaultProduct  from '../asset/images/large-default-product.jpg';
import { getProductosByTipoBusqueda } from "../services/productoServ";
import { withTranslation } from 'react-i18next';

import './categoryArea.css';

class CategoryArea extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          listaProductosMasValorados: undefined,
          listaProductosOfertas:undefined,
          listaProductosHot: undefined,
          cargandoHot:true,
          cargandoMasValorados:true,
          cargandoProductosOfertas:true,
          mensaje:true,
          textoMensaje:'',
          variante:'',
      };  
    }

    componentDidMount()
    {
      this.cargarProductosMasValorados(); 
      this.cargarProductosOfertas(); 
      this.cargarProductosHot(); 
  }

  cargarProductosMasValorados(){
    let pagination=0;
    let row=100;
    let filter=[{parametro:"TIPO_BUSQUEDA",valor:"MAS_VALORADOS"}];
    getProductosByTipoBusqueda(pagination,row,filter).then(result => {
          let  datos = result.data.data;
          this.setState({ listaProductosMasValorados: [...datos], cargandoMasValorados:false  });
    }).catch((error)=>{
      this.setState({ cargandoMasValorados:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
      });
  }

  cargarProductosOfertas(){
    let pagination=0;
    let row=100;
    let filter=[{parametro:"TIPO_BUSQUEDA",valor:"EN_OFERTA"}];
    getProductosByTipoBusqueda(pagination,row,filter).then(result => {
          let  datos = result.data.data;
          this.setState({ listaProductosOfertas: [...datos], cargandoProductosOfertas:false  });
    }).catch((error)=>{
      this.setState({ cargandoProductosOfertas:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
      });
  }

  cargarProductosHot(){
    let pagination=0;
    let row=100;
    let filter=[{parametro:"TIPO_BUSQUEDA",valor:"PRODUCTO_HOT"}];
    getProductosByTipoBusqueda(pagination,row,filter).then(result => {
          let  datos = result.data.data;
          this.setState({ listaProductosHot: [...datos], cargandoHot:false  });
    }).catch((error)=>{
      this.setState({ cargandoHot:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
      });
  }

  onImageError(e) {
    e.target.src = imageDefaultProduct;
  }

  goDetalle(producto){
    this.props.history.push(`/producto/detalle/${producto.idProducto}`);
  }

  render(){

      const  {t}=this.props;
      const {listaProductosMasValorados,listaProductosOfertas,listaProductosHot} = this.state;
      return(
            <div className="jtv-category-area">
            <Container>
              <Row>
                <Col sm={8}>
                  <div className="jtv-single-cat">
                    <h2 className="cat-title">{t('front.categoryArea.topRated.tittle.label')}</h2>
                    {listaProductosMasValorados!==undefined?
                    listaProductosMasValorados.map((dat,idx)=>{
                      return(<Card key={idx} style={{"margin":"2px"}}>
                        <Row>
                          <Col sm={4}>
                          <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{"width":"11rem"}}  onError={this.onImageError} />
                          </Col>
                          <Col sm={8}>
                          <Card.Body>
                          <Card.Title>{dat.nombre}</Card.Title>
                          <Card.Text>{dat.descripcion}</Card.Text>
                          <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver más...</Button>
                        </Card.Body>
                          </Col>
                        </Row>                                 
                      </Card>);
                    })
                    :
                    <>Cargando...</>
                    }
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="jtv-single-cat">
                    <h2 className="cat-title">{t('front.categoryArea.onSale.tittle.label')}</h2>
                    {listaProductosOfertas!==undefined?
                    listaProductosOfertas.map((dat,idx)=>{
                      return(<Card key={idx} style={{"margin":"2px"}}>
                        <Row>
                          <Col sm={4}>
                          <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{"width":"11rem"}}  onError={this.onImageError} />
                          </Col>
                          <Col sm={8}>
                          <Card.Body>
                          <Card.Title>{dat.nombre}</Card.Title>
                          <Card.Text>{dat.descripcion}</Card.Text>
                          <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver más...</Button>
                        </Card.Body>
                          </Col>
                        </Row>                                 
                      </Card>);
                    })
                    :
                    <>Cargando...</>
                    }
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="jtv-single-cat">
                    <h2 className="cat-title">{t('front.categoryArea.market.tittle.label')}</h2>
                    {listaProductosHot!==undefined?
                    listaProductosHot.map((dat,idx)=>{
                      return(<Card key={idx} style={{"margin":"2px"}}>
                        <Row>
                          <Col sm={4}>
                          <Card.Img variant="top" src={multimediaProductoURL(dat.idProducto)} style={{"width":"11rem"}}  onError={this.onImageError} />
                          </Col>
                          <Col sm={8}>
                          <Card.Body>
                          <Card.Title>{dat.nombre}</Card.Title>
                          <Card.Text>{dat.descripcion}</Card.Text>
                          <Button variant="primary" onClick={()=>this.goDetalle(dat)}>Ver más...</Button>
                        </Card.Body>
                          </Col>
                        </Row>                                 
                      </Card>);
                    })
                    :
                    <>Cargando...</>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        );
    }
}


export default withTranslation()(withRouter(CategoryArea));