export const ActionTypes={
    ADD_PRODUCTO_CARRITO:"ADD_PRODUCTO_CARRITO",
    REMOVE_PRODUCTO_CARRITO:"REMOVE_PRODUCTO_CARRITO",
    FETCH_CARRITO:"FETCH_CARRITO",
    PRODUCTO_DETALLE:"PRODUCTO_DETALLE",
    FETCH_PRODUCTO_DETALLE:"FETCH_PRODUCTO_DETALLE",
    PASO_PROCESAR_PAGO:"PASO_PROCESAR_PAGO", 
    SALTO_LOGIN:"SALTO_LOGIN", 
    INIT_PROCESAR_PAGO:"INIT_PROCESAR_PAGO",
    ESTADO_PROCESO_PAGO:"ESTADO_PROCESO_PAGO",  
    FIN_PROCESO_PAGO:"FIN_PROCESO_PAGO",  
    ADD_PRODUCTO_FAVORITO:"ADD_PRODUCTO_FAVORITO",  
    REMOVE_PRODUCTO_FAVORITO:"REMOVE_PRODUCTO_FAVORITO",
    FETCH_LIST_FAVORITO:"FETCH_LIST_FAVORITO",
    LIST_FAVORITO:"LIST_FAVORITO",
    ADD_PEDIDO_PROGRAMADO:"ADD_PEDIDO_PROGRAMADO",  
    REMOVE_PEDIDO_PROGRAMADO:"REMOVE_PRODUCTO_PEDIDO_PROGRAMADO",
    FETCH_LIST_PEDIDO_PROGRAMADO:"FETCH_LIST_PEDIDO_PROGRAMADO",
    LIST_PEDIDO_PROGRAMADO:"LIST_PEDIDO_PROGRAMADO",
}