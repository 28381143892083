import React, { Component } from 'react';
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';

import {getQueryVariable} from '../../components/Utils';

import { reseteoPass } from '../../services/usuarioServ';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MensajeAlert from '../../components/MensajeAlert';
import { withTranslation } from 'react-i18next';

class ResetPassword extends Component {

    constructor(props){
       super(props);
       let token = getQueryVariable('token');       
       this.state = {
        validated: false,
        textoMensaje:"",
        token:token,
        usuario:{password:"",confirmPassword:""},
        tipoMensaje:"",
    };
    }

    myChangeHandler = (event) => {

        const { usuario } = this.state;
       
        let nam = event.target.name;
        let val = event.target.value;
    
        usuario[nam]=val;
    
        this.setState({usuario: usuario});
    }


    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { usuario,token} = this.state;
        const form = event.currentTarget; 
        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.preventDefault();
            event.stopPropagation();
        }else{
            if(usuario.password !== usuario.confirmPassword){
                this.setState({ validated: false,desableButton: false ,
                    textoMensaje:`La confirmación del password no coincide, por favor valide los datos.` ,
                    tipoMensaje:"danger" });
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            usuario.token = token;
            reseteoPass(usuario).then(result => {           
                if(result.data === null){
                    this.setState({ validated: false,desableButton: false ,
                        textoMensaje:`Falla en el proceso` ,
                        tipoMensaje:"danger" });
                }
                this.setState({ validated:true ,  usuario:{password:"",confirmPassword:""}, textoMensaje:`Se ha enviado realizado el proceso de forma exitosa`, tipoMensaje:"success"  });          
            }).catch((error)=>{
                this.setState({ validated:true  , textoMensaje:`Ocurrio un error en el proceso`, tipoMensaje:"danger"  });
                event.preventDefault();
                event.stopPropagation();
            });
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render () {

        const { usuario , textoMensaje,tipoMensaje,validated} = this.state;

        return(
        <Container fluid="md" className="p-5">
                    <img src={logo} alt="logo" className="p-1"/>
                    <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                            <Card.Img variant="top" src={imageLogin} />
                            <Card.Body>
                                    <Col className="col text-center">
                                        <h2>Resetear Passworddddd</h2>  
                                        { textoMensaje!==""?<MensajeAlert mensaje={textoMensaje} variante={tipoMensaje} />:<></> }  
                                        <Form className="p-3 text-center" noValidate validated={validated} onSubmit={this.handleSubmit} >                  
                                            <FloatingLabel  controlId="floatingInputPass"  label="Contraseña"  className="mb-3" >
                                                <Form.Control required type="password" name="password" placeholder="Password" value={usuario.password} onChange={this.myChangeHandler} />
                                            </FloatingLabel>  
                                            <FloatingLabel  controlId="floatingInputRepass"  label="Confirmar Contraseña"  className="mb-3" >
                                                <Form.Control required type="password" name="confirmPassword" placeholder="Confirmar Password" value={usuario.confirmPassword} onChange={this.myChangeHandler} />
                                            </FloatingLabel> 
                                            <div className="d-grid gap-2 mx-auto">                                    
                                                <button type="submit" value="Actualizar" className="btn btn-success mx-1">Actualizar</button> 
                                                <button type="button" className="btn btn-outline-success ms-1"  onClick={()=>{ this.props.history.push("/"); }}><i className="fas fa-chevron-left"></i>Ir a Inicio</button>                         
                                            </div>                             
                                        </Form> 
                                    </Col> 
                            </Card.Body>
                            </Card>  
                    </Row>                                         
        </Container>
        );
    }

}

export default withTranslation()(ResetPassword);