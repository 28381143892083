import React from "react";

export class ButtonTop extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <a href="#" className="totop"> </a> 
        );}
    }