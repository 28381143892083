import React from "react";
import imageApp from '../asset/images/inicio_app.png';
import "./imageApp.css";

export class ImageApp extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <img alt="vamos-futbol" className="imageApp_float" src={imageApp} width="349px" height="770px" />
        );}
}